import React, { useContext, useEffect, useState } from "react";
import { LanguageSelector } from "../components/language-selector";
import { ZoozaApi } from "../services/Axios";
import { ZoozaContext } from "../services/zooza.context";
import { useSearchParams } from "react-router-dom";
import { Member, ZoozaContextTypes } from "../types/types";
import { Link } from "react-router-dom";
import { ZoozaSelect } from "../components/inputs";
import moment from "moment";
import i18n from "../locale";

import {
  ZoozaTable,
  ZoozaTableBody,
  ZoozaTableCell,
  ZoozaTableHeader,
  ZoozaTablePagination,
  ZoozaTableRow,
  ZoozaTableRowItem,
} from "../components/zooza-table-components";
import { useTranslation } from "react-i18next";

const today = moment().format("YYYY-MM-DD");
const nextMonth = moment().clone().add(1, "months").format("YYYY-MM-DD");
export const HomePage = () => {
  const lang = i18n.language;
  const { isAlowed } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="body">
        <div className="app_page_header">
          <h1 style={{ marginBottom: "20px" }}>{t("welcome_title")}</h1>
        </div>
        <div className="z2 app_page_layout">
          <MyUpcomingEvents />
          {isAlowed?.(["owner", "receptionist", "assistant"]) && (
            <RecentEventsMembersTable />
          )}
        </div>
        <LanguageSelector></LanguageSelector>
      </div>
    </>
  );
};
const MyUpcomingEvents = () => {
  const lang = i18n.language;
  const { courses, userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [recentEvents, setRecentEvents] = useState<any>();
  useEffect(() => {
    if (userData !== null) {
      getRecentEvents();
    }
  }, [userData]);

  const getRecentEvents = async () => {
    try {
      const { data } = await ZoozaApi.get(`/events?upcoming_events=true&1=1`);
      setRecentEvents(data);
      return data;
    } catch (error) {
      console.error(error);
    }
  };
  const { t } = useTranslation();
  return (
    <ZoozaTable>
      <ZoozaTableHeader>
        <div className="results_found">
          <h3>
            {" "}
            <em className="icon-calendar"></em> {t("my_upcoming_events")}
          </h3>
        </div>
      </ZoozaTableHeader>
      <ZoozaTableBody>
        {recentEvents &&
          recentEvents.length > 0 &&
          recentEvents.map((event: any) => {
            const course = courses?.find(
              (course) => course.id === event.course_id
            );
            return (
              <ZoozaTableRow key={event.id}>
                <ZoozaTableRowItem>
                  <ZoozaTableCell span="5">
                    <span aria-label={t("global__date_time")}>
                      {t("global__date_time")}
                    </span>
                    {moment(event.date).locale(lang).format("LLLL")}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="5">
                    <span aria-label={t("global__course")}>
                      {t("global__course")}
                    </span>
                    {course?.name}
                  </ZoozaTableCell>
                  <ZoozaTableCell span="2">
                    {" "}
                    <Link to={"calendar/" + event.id}>
                      {t("home_receptionist__go_to_event")}
                    </Link>
                  </ZoozaTableCell>
                </ZoozaTableRowItem>
              </ZoozaTableRow>
            );
          })}
      </ZoozaTableBody>
      <ZoozaTablePagination></ZoozaTablePagination>
    </ZoozaTable>
  );
};

const RecentEventsMembersTable = () => {
  const lang = i18n.language;
  const { members } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [lectorEvents, setLectorEvents] = useState<any>();
  const [searchParams] = useSearchParams();
  const findLectorEvents = async () => {
    const memberParam = searchParams.get("member")
      ? searchParams.get("member")
      : members?.[0].id;

    if (memberParam) {
      try {
        const { data } = await ZoozaApi.get(
          `/users/${memberParam}/report?start=${today}&end=${nextMonth}&unplanned=false&1=1`
        );
        setLectorEvents(data.events);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    findLectorEvents();
  }, [searchParams.get("member"), members]);
  useEffect(() => {
    if (searchParams.get("member") === null) {
      searchParams.set("member", String(members?.[0].id));
    }
  }, [members]);
  const { t } = useTranslation();
  return (
    <ZoozaTable>
      <ZoozaTableHeader>
        <div className="results_found">
          <h3>
            {" "}
            <em className="icon-hourglass"></em> {t("lector_events")}
          </h3>
        </div>

        {
          <ZoozaSelect param={"member"}>
            {members?.map((lector: Member) => (
              <option key={lector.id} value={lector.id}>
                {`${lector.first_name} ${lector.last_name}`}
              </option>
            ))}
          </ZoozaSelect>
        }
      </ZoozaTableHeader>
      <ZoozaTableBody>
        {lectorEvents &&
          lectorEvents?.map((lectorEvent: any) => (
            <ZoozaTableRow key={lectorEvent.id}>
              <ZoozaTableRowItem>
                <ZoozaTableCell span="5">
                  <span aria-label={t("global__date_time")}>
                    {t("global__date_time")}
                  </span>
                  {moment(lectorEvent.date_start).locale(lang).format("LLLL")}
                </ZoozaTableCell>
                <ZoozaTableCell span="5">
                  <span aria-label={t("global__course")}>
                    {t("global__course")}
                  </span>
                  {lectorEvent.course_name}
                </ZoozaTableCell>
                <ZoozaTableCell span="2">
                  <Link to={"calendar/" + lectorEvent.event_id}>
                    {t("home_receptionist__go_to_event")}
                  </Link>
                </ZoozaTableCell>{" "}
              </ZoozaTableRowItem>
            </ZoozaTableRow>
          ))}{" "}
      </ZoozaTableBody>
      <ZoozaTablePagination></ZoozaTablePagination>
    </ZoozaTable>
  );
};
