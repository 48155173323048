import React, { useContext, useEffect, useState } from "react";
import { ZoozaApi } from "../../services/Axios";
import { Input } from "../../components/input";
import {
  RegistrationResult,
  Registrations,
} from "../../types/registration-types";
import { RegistrationRow } from "./registration-row";
import { useTranslation } from "react-i18next";
import {
  ZoozaTableBody,
  ZoozaTable,
  ZoozaTableHeader,
  ZoozaTableSortingOptions,
  ZoozaTablePagination,
} from "../../components/zooza-table-components";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import { Link } from "react-router-dom";
import { AddPaymentModal } from "../../components/add-payment-modal";
import useDebounce from "../../hooks/debounce-search";

export const RegistrationsPage = () => {
  const { t } = useTranslation();
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [registrationsData, setRegistrationsData] = useState<Registrations>();
  const [registrations, setRegistrations] = useState<RegistrationResult[]>();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const [open, setOpen] = React.useState(false);
  const [registration, setRegistration] = useState<RegistrationResult>();
  const debouncedSearchTerm = useDebounce(search, 500);
  useEffect(() => {
    getRegistrations();
  }, [page, debouncedSearchTerm]);

  const getRegistrations = async () => {
    try {
      const { data: registrations } = await ZoozaApi.get(
        `/registrations?advanced_search=1&user=${debouncedSearchTerm}&ef_full_name=&page=${
          page - 1
        }&status=null&created_from=null&created_to=null`
      );
      setRegistrationsData(registrations);
      setRegistrations(registrations.results);
    } catch (error) {
      console.error("Error fetching registrations:", error);
    }
  };

  // const onSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSort(e.target.value);
  // };
  const filterRegistrations = (registrationsResults?: RegistrationResult[]) => {
    if (courses?.length !== 0) {
      if (registrationsResults) {
        const updatedRegistrations = registrationsResults.map(
          (registration) => {
            const course = courses?.find(
              (c) => c.id === registration.course_id
            );
            if (course) {
              return { ...registration, course: course };
            } else {
              return registration;
            }
          }
        );
        return updatedRegistrations;
      }
    }
  };
  return (
    <div className="z2 app_page_layout">
      <div className="z2 app_page_toolbar">
        <Input
          value={search || ""}
          onChange={(e) => {
            const searchTerm = e.target.value;
            setSearch(searchTerm);
          }}
          label={t("global__search")}
          type={"search"}
          placeholder={t("global__search")}
          id={"search"}
        ></Input>
        <Link to="create/" role="button" className="z2 primary">
          {t("create_registration")}
        </Link>
      </div>

      <div className="app_page_column span_1">
        <ZoozaTable>
          <ZoozaTableHeader>
            <div className="results_found">
              {registrationsData?.total} {t("results_count__label")}
            </div>

            {/* <ZoozaTableSortingOptions
              value={sort}
              onChange={onSortChange}
              options={[
                { value: "newest", label: t("newest__label") },
                { value: "oldest", label: t("oldest__label") },
                { value: "name_ascending", label: t("name_ascending__label") },
                {
                  value: "name_descending",
                  label: t("name_descending__label"),
                },
              ]}
            /> */}
          </ZoozaTableHeader>

          <ZoozaTableBody>
            {filterRegistrations(registrations)?.map(
              (registration: RegistrationResult, index: number) => (
                <RegistrationRow
                  key={index}
                  registration={registration}
                  setOpen={setOpen}
                  setRegistration={setRegistration}
                />
              )
            )}
          </ZoozaTableBody>

          <ZoozaTablePagination
            page={page}
            total={registrationsData?.total}
            setPage={setPage}
          />
        </ZoozaTable>
      </div>
      <AddPaymentModal
        open={open}
        setOpen={setOpen}
        order_id={registration?.registration_id}
        type="payment"
      />
    </div>
  );
};
