import React, { useEffect, useState } from "react";
import moment from "moment";
import { CalendarEvent, Member, Place, Room } from "../types/types";
import "../styles/calendar.css";
import { DailyAttendance } from "../types/schedue-type";
import { CalendarEventItem } from "./event-item";
import { useNavigate } from "react-router-dom";
import { ZoozaApi } from "../services/Axios";

export const DailyOverview = ({
  place,
  members,
  events,
  date,
}: {
  place: Place | undefined;
  members: Member[];
  events: CalendarEvent[] | undefined;
  handleDate: (num: number) => void;
  date: string;
}) => {
  const [attendances, setAttendances] = useState<DailyAttendance[]>([]);
  const navigate = useNavigate();
  const times = Array.from(Array(24).keys()).map((hour) => {
    const time = moment().hour(hour).minute(0).format("HH:mm");
    return {
      id: hour,
      time: time,
    };
  });
  const filteredEvents = place
    ? events?.filter((event) => event.place_id === place.id)
    : events;
  const onClickCreateEvent = (e: any) => {
    const [time, member_id, place_id] = e.target.id.split("-");

    navigate(
      `/registrations/create/?time=${time}&member=${member_id}&place=${place_id}&date=${moment(
        date
      ).format("YYYY-MM-DD")}`
    );
  };
  const getDailyAttendance = async () => {
    const place_id = place?.id;
    const { data } = await ZoozaApi.get(
      `/attendance?daily=true&date=${date}&place_id=${place_id}&replacements_only=false&1=1`
    );
    return data;
  };
  useEffect(() => {
    getDailyAttendance()
      .then((data) => {
        setAttendances(data);
      })
      .catch((err) => console.log(err));
  }, [events, date, place, members]);

  return (
    <div className="calendar">
      <div className="calendar-header"></div>
      <div className="calendar-body">
        <ul>
          {filteredEvents?.map((event) => (
            <CalendarEventItem
              attendances={attendances}
              key={event?.id}
              event={event}
              place={place}
              members={members}
            />
          ))}
        </ul>
        <div className="calendar-lectors">
          <ul>
            <div style={{ height: 50 }}></div>
            <li className="place-separator">{place?.name}</li>
            {members?.map((member) => (
              <li key={member.id}>
                {member.first_name} {member.last_name}
              </li>
            ))}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator">{room.name}</li>
                <ul>
                  {members?.map((member) => (
                    <li key={member.id}>
                      {member.first_name} {member.last_name}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </ul>
        </div>
        {times?.map((time) => (
          <div className="time-slot">
            <div className="time-cell" key={time.time}>
              {time.time}
            </div>
            {place ? (
              <li className="place-separator"></li>
            ) : (
              <li className="place-separator"></li>
            )}
            {members?.map((member) => (
              <li
                onClick={onClickCreateEvent}
                id={`${time.time}-${member.id}-${place?.pid}`}
                key={member.id}
              ></li>
            ))}
            {place?.rooms.map((room) => (
              <React.Fragment key={room.id}>
                <li className="place-separator"></li>
                <ul>
                  {members?.map((member) => (
                    <li
                      onClick={onClickCreateEvent}
                      id={`${time.time}-${member.id}-${room.pid}`}
                      key={member.id}
                    ></li>
                  ))}
                </ul>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
