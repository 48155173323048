import React, { createContext, useContext, useState } from "react";
import { ZoozaContext } from "../../services/zooza.context";
import {
  CreateRegistrationContextTypes,
  Place,
  ZoozaContextTypes,
} from "../../types/types";
import { Course, ExtraField } from "../../types/courses-types";
import {
  FetchedNewRegistrations,
  NewRegistration,
  Event,
} from "../../types/registration-types";
import { useSearchParams } from "react-router-dom";
import { ZoozaApi } from "../../services/Axios";
import moment from "moment";
export const CreateRegistrationContext = createContext({});
export const CreateRegistrationProvider = ({
  children,
}: React.PropsWithChildren<CreateRegistrationContextTypes>) => {
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [events, setEvents] = useState<Event[]>([]);
  const [schedule, setSchedule] = useState<any>(null);
  const [course, setCourse] = useState<Course | undefined>(courses?.[0]);
  const [place, setPlace] = useState<Place | undefined>();
  const [room, setRoom] = useState<any>(null);
  const [member, setMember] = useState<any>(null);
  const [fetchProgress, setFetchProgress] = useState(0);
  const [fetchedSchedules, setFetchedSchedules] = useState<any>();
  const [fetchedEvents, setFetchedEvents] = useState<any>();
  const [fetchedRegistrations, setFetchedRegistrations] =
    useState<FetchedNewRegistrations>();
  const [agreements, setAgreements] = useState<any>();
  const [pid, setPid] = useState<string | null>(searchParams.get("place"));
  const [scheduleName, setScheduleName] = useState<string>("");
  const lang = localStorage.getItem("i18nextLng") || "en";
  const initialExtraFieldsValues = course?.extra_fields
    ?.filter((field) => field.active === true)
    ?.reduce(
      (acc, field) => {
        if (field.column_name === "slots") {
          acc[field.column_name] = "1";
        } else {
          acc[field.column_name] = "";
        }
        return acc;
      },
      { first_name: "", last_name: "", phone: "", email: "", slots: "1" } as {
        [key in
          | ExtraField["column_name"]
          | "first_name"
          | "last_name"
          | "phone"
          | "email"
          | "slots"]: string;
      }
    );
  const [registrations, setRegistrations] = useState<NewRegistration[]>([
    initialExtraFieldsValues,
  ]);
  const createSchedule = async (
    schedueName: string,
    course: Course | undefined,
    member_id: number | undefined,
    place_id: number | undefined,
    room_id: number | undefined,
    capacity: number
  ) => {
    try {
      const { data: schedule } = await ZoozaApi.post("/schedules", {
        name: schedueName,
        course_id: course?.id,
        trainer_id: member_id,
        place_id: place_id,
        room_id: room_id,
        capacity: capacity,
        online_registration: true,
        price: course?.price,
        registration_fee: course?.registration_fee,
        billing_period_id: "",
        duration: events[0].duration,
        status: "active",
      });
      return { schedule: schedule, progress: 2 };
    } catch (e) {
      console.error(e);
    }
  };
  const createEvents = async (
    events: Event[],
    course: Course | undefined,
    schedule_id: number | undefined
  ) => {
    const newEvents = events.map((event: Event) => ({
      duration: event.duration,
      date: event.date,
      date_string: moment(event.date).format("YYYY-MM-DD"),
      day: moment(event.date).locale(lang).format("DD"),
      time_string:
        moment(event.date).hours() * 60 + moment(event.date).minutes(),
      course_id: course?.id,
      schedule_id: schedule_id ? schedule_id : localStorage.getItem("schedule"),
      trainer_id: event.trainer_id,
      trainer_rate_type_id: event.trainer_rate_type_id,
      place_id: event.place_id,
      room_id: event.room_id,
      first_of_month: false,
      full_date: moment(event.date).format("DD. MM. YYYY"),
      has_event: true,
      holiday: null,
      //  is_holiday: false,
      month: moment(event.date).format("MM"),
      short_date: moment(event.date).format("DD. MM."),
      time: moment(event.date).format("HH:mm"),
      time_end: moment(event.date)
        .add(event.duration, "minutes")
        .format("HH:mm"),
      weekend: false,
    }));
    try {
      const { data: ResponseEvents } = await ZoozaApi.post("/events", {
        events: newEvents,
      });
      return { events: ResponseEvents, progress: 3 };
    } catch (e) {
      console.error(e);
    }
  };
  const createRegistrations = async (
    registrations: NewRegistration[],
    course: Course | undefined,
    schedule_id: number | undefined,
    agreements: any,
    place_id: number | undefined,
    room_id: number | undefined,
    member_id: number | undefined
  ) => {
    const persons = registrations?.map((registration) => {
      const extraFields =
        (course?.extra_fields
          ?.filter(
            (field) =>
              registration?.[field.column_name as keyof typeof registration] !==
              ""
          )
          .map((field) => ({
            column_name: field.column_name,
            value:
              registration?.[field.column_name as keyof typeof registration],
          }))
          .filter((item) => item.value !== undefined && item.value !== "") as {
          column_name:
            | "dob"
            | "full_name"
            | "address"
            | "business_name"
            | "business_address"
            | "business_id"
            | "tax_id"
            | "vat"
            | "slots"
            | "identification_number"
            | "extra_field_1"
            | "extra_field_2"
            | "extra_field_3"
            | "extra_field_4"
            | "extra_field_5";
          value: string;
        }[]) || [];

      // Add or update "slots" field
      const slotsField = extraFields.find(
        (field) => field.column_name === "slots"
      );
      if (slotsField === undefined) {
        extraFields.push({
          column_name: "slots",
          value:
            registration?.slots !== undefined && registration?.slots !== ""
              ? registration?.slots
              : "1",
        });
      } else {
        slotsField.value =
          registration?.slots !== undefined && registration?.slots !== ""
            ? registration?.slots
            : "1";
      }

      return {
        basic_fields: {
          first_name: registration?.first_name,
          last_name: registration?.last_name,
          email: registration?.email,
          phone: registration?.phone,
        },
        extra_fields: extraFields,
      };
    });

    try {
      const { data: ResponseRegistrations } = await ZoozaApi.post(
        "/registrations",
        {
          course_id: course?.id,
          schedule_id: schedule_id
            ? schedule_id
            : localStorage.getItem("schedule"),
          business_order: false,
          persons: persons,
          agreements: agreements,
          payment_method: "cash",
          place_id: place_id,
          room_id: room_id,
          trainer_id: member_id,
          schedule_full_opt_in: true,
          late_registration_opt_in: true,
          payment_schedule: null,
          payment_method_transfer_options: null,
        }
      );
      return { registrations: ResponseRegistrations, progress: 4 };
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async () => {
    try {
      if (fetchProgress === 0 && !fetchedSchedules) {
        const scheduleData = await createSchedule(
          scheduleName,
          course,
          member?.id,
          place?.id,
          room?.id,
          registrations.length
        );
        setFetchProgress(scheduleData?.progress ? 1 : 0);
        if (scheduleData?.schedule) {
          setFetchedSchedules(scheduleData.schedule);
          localStorage.setItem(
            "schedule",
            JSON.stringify(scheduleData.schedule.id)
          );
          const eventsData = await createEvents(
            events,
            course,
            scheduleData.schedule.id
          );
          setFetchProgress(eventsData?.progress ? 2 : 2);
          if (eventsData?.events) {
            setFetchedEvents(eventsData.events);
            const registrationsData = await createRegistrations(
              registrations,
              course,
              scheduleData.schedule.id,
              agreements,
              place?.id,
              room?.id,
              member?.id
            );
            setFetchProgress(registrationsData?.progress ? 4 : 3);
            if (registrationsData?.registrations) {
              setFetchedRegistrations(registrationsData.registrations);
            }
          }
        }
      } else if (fetchProgress === 2 && localStorage.getItem("schedule")) {
        const eventsData = await createEvents(
          events,
          course,
          fetchedSchedules?.schedule
            ? fetchedSchedules?.id
            : localStorage.getItem("schedule")
        );
        setFetchProgress(eventsData?.progress ? 3 : 2);
        if (eventsData?.events) {
          setFetchedEvents(eventsData.events);
          const registrationsData = await createRegistrations(
            registrations,
            course,
            fetchedSchedules
              ? fetchedSchedules?.id
              : localStorage.getItem("schedule"),
            agreements,
            place?.id,
            room?.id,
            member?.id
          );
          setFetchProgress(registrationsData?.progress ? 4 : 3);
          if (registrationsData?.registrations) {
            setFetchedRegistrations(registrationsData.registrations);
          }
        }
      } else if (
        fetchProgress === 3 &&
        localStorage.getItem("schedule") &&
        fetchedEvents
      ) {
        const registrationsData = await createRegistrations(
          registrations,
          course,
          fetchedSchedules
            ? fetchedSchedules?.id
            : localStorage.getItem("schedule"),
          agreements,
          place?.id,
          room?.id,
          member?.id
        );
        if (registrationsData?.registrations) {
          setFetchProgress(registrationsData?.progress ? 4 : 4);
          setFetchedRegistrations(registrationsData.registrations);
        }
      }
    } catch (error) {
      console.error("Error in form submission:", error);
    }
  };
  const handleRegistartionsCreate = async () => {
    createRegistrations(
      registrations,
      course,
      fetchedSchedules
        ? fetchedSchedules?.id
        : localStorage.getItem("schedule"),
      agreements,
      place?.id,
      room?.id,
      member?.id
    );
  };
  console.log(events);
  return (
    <CreateRegistrationContext.Provider
      value={{
        handleSubmit,
        course,
        setCourse,
        fetchProgress,
        setRegistrations,
        registrations,
        setPlace,
        place,
        setRoom,
        room,
        setMember,
        member,
        setEvents,
        events,
        setSchedule,
        schedule,
        agreements,
        setAgreements,
        pid,
        setPid,
        setScheduleName,
        fetchedRegistrations,
      }}
    >
      {children}
    </CreateRegistrationContext.Provider>
  );
};
