import React, { useContext, useEffect, useState } from "react";
import { ZoozaApi } from "../../services/Axios";
import { useNavigate, useParams } from "react-router-dom";
import { Registration } from "../../types/registration-types";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import {
  InvoiceProfilesApiResponse,
  PaymentsApiResponse,
} from "../../types/payments-types";
import { PaymentsStatusComponent } from "./payment-status-component";
import { InvoiceComponent } from "./invoice-component";
import { PaymentListComponent } from "./payments-list-component";
import { useTranslation } from "react-i18next";
export const PaymentsPage = () => {
  const { t } = useTranslation();
  const [payments, setPayments] = useState<PaymentsApiResponse>();
  const [registration, setRegistration] = useState<Registration>();
  const [invoiceProfiles, setInvoiceProfiles] =
    useState<InvoiceProfilesApiResponse>();
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const course = courses?.find(
    (course) => course.id === registration?.course_id
  );
  const getPayments = async () => {
    try {
      const { data } = await ZoozaApi.get(
        `/payments?sort_by=created_desc&provider=zooza&order_type=course&order_id=${id}&page_size=100&1=1`
      );
      setPayments(data);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };
  const getRegistration = async () => {
    try {
      const { data } = await ZoozaApi.get(`/registrations/${id}`);
      setRegistration(data);
    } catch (error) {
      console.error("Error fetching registration:", error);
    }
  };
  const getInvoiceProfiles = async () => {
    try {
      const { data } = await ZoozaApi.get(`/invoice_profiles`);
      setInvoiceProfiles(data);
    } catch (error) {
      console.error("Error fetching invoice profiles:", error);
    }
  };

  useEffect(() => {
    Promise.all([getPayments(), getRegistration(), getInvoiceProfiles()]);
  }, []);

  return (
    <div className="z2 app_page_layout">
      <div className="z2 app_page_toolbar">
        <button onClick={() => navigate(-1)} className="z2">
          {t("global__back")}
        </button>
      </div>
      <div className="app_page_body">
        <div className="app_page_column span_2">
          <PaymentsStatusComponent
            registration={registration}
            payments={payments?.data}
          />
        </div>
        <div className="app_page_column span_2">
          <InvoiceComponent
            invoiceProfiles={invoiceProfiles?.data}
            registration={registration}
            course={course}
          />
        </div>
        <div className="app_page_column span_4">
          <PaymentListComponent payments={payments?.data} />
        </div>
      </div>
    </div>
  );
};
