import { t, use } from "i18next";
import React, { useContext, useEffect } from "react";
import { Modal } from "../../components/modal";
import { Course } from "../../types/courses-types";
import { InputNumber } from "../../components/add-payment-modal";
import moment from "moment";
import { DateTimePicker } from "../../components/date-picker";
import { ZoozaApi } from "../../services/Axios";
import { convertToCurrency } from "../../services/curency-service";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import "../../styles/payment-modal-cretaion.css";
import { get_balance } from "../payment-page/payment-status-component";
import { Payment, paymentTypes } from "../../types/payments-types";
import _debounce from "lodash/debounce";
type PaymentModalCreation = {
  setOpen: (open: boolean) => void;
  open: boolean;
  order_id: number;
  course: Course | undefined;
  setRes: (res: any) => void;
  res: any;
};
export const PaymentModalCreation = ({
  setOpen,
  setRes,
  open,
  course,
  order_id,
  res,
}: PaymentModalCreation) => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState("");
  const [paid, setPaied] = React.useState<number | string>(0);
  const [discount, setDiscount] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [paymentDate, setPaymentDate] = React.useState(moment());
  const [coursePrice, setCoursePrice] = React.useState(0);
  const [courseFee, setCourseFee] = React.useState(0);
  const [error, setError] = React.useState<unknown | undefined>();
  const [paidWholeCourse, setPaidWholeCourse] = React.useState(true);
  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const debouncedSubmit = _debounce(async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  }, 100);
  const handleSubmit = () => {
    if (!isSubmitting) {
      debouncedSubmit();
    }
  };
  const onSubmit = async () => {
    setButtonDisabled(true);
    const isOK = await handlePayment();
    setOpen(false);
    setButtonDisabled(false);
  };
  const getPayments = async () => {
    const { data: paymentsData } = await ZoozaApi.get(
      `/payments?order_id=${order_id}`
    );
    return paymentsData;
  };
  useEffect(() => {
    getPayments().then((res) => {
      setPayments(res.data);
    });
    if (!open) {
      setIsSubmitting(false);
    }
  }, [order_id, open]);

  const countPayments = (
    id: number,
    payment_type: String[] | readonly string[]
  ) => {
    const paymentsTemp = payments?.filter((payment) =>
      payment_type.includes(payment.transaction_type)
    );
    const paid = paymentsTemp?.reduce((acc, payment) => {
      if (payment.amount) {
        return get_balance(
          Number(acc),
          Number(payment.amount),
          Boolean(payment.amount)
        );
      } else return acc;
    }, 0);
    return paid;
  };

  const alreadyPayed = countPayments(Number(order_id), paymentTypes);

  const alreadyDiscountPayment = countPayments(Number(order_id), [
    "discount",
    "discount_correction",
  ]);
  const alreadyFeePayment = Math.abs(
    countPayments(Number(order_id), [
      "registration_fee",
      "registration_fee_correction",
    ])
  );
  const alreadyCoursePayment = Math.abs(
    countPayments(Number(order_id), [
      "course_payment",
      "course_payment_correction",
    ])
  );

  const differenceCourseFee = Number(alreadyFeePayment) - Number(courseFee);
  const differenceDiscount = Number(discount) - Number(alreadyDiscountPayment);
  const alreadyPaidDiference = paidWholeCourse
    ? Number(coursePrice) +
      Number(courseFee) -
      Number(alreadyPayed) -
      Number(discount)
    : Number(paid) - Number(alreadyPayed);
  const differenceCoursePrice =
    Number(alreadyCoursePayment) - Number(coursePrice);
  useEffect(() => {
    setCoursePrice(Number(alreadyCoursePayment));
    setCourseFee(Number(alreadyFeePayment));
    setPaied(Number(alreadyPayed));
  }, [payments]);

  const coursePaymentReqBody = {
    order_id: order_id,
    order_type: "course",
    variable_symbol: order_id,
    amount: differenceCoursePrice,
    note: note,
    transaction_type:
      Number(alreadyCoursePayment) > coursePrice
        ? "course_payment_correction"
        : "course_payment",
    origin: "manual",
    posting_date: moment().format("YYYY-MM-DD"),
    value_date: moment(paymentDate).format("YYYY-MM-DD"),
  };
  const paymentReqBody = {
    order_id: order_id,
    order_type: "course",
    variable_symbol: order_id,
    amount: alreadyPaidDiference,
    note: note,
    transaction_type:
      alreadyPaidDiference > 0 ? paymentType : "credit_correction",
    origin: "manual",
    posting_date: moment().format("YYYY-MM-DD"),
    value_date: moment(paymentDate).format("YYYY-MM-DD"),
  };
  const discountReqBody = {
    order_id: order_id,
    order_type: "course",
    variable_symbol: order_id,
    amount: differenceDiscount,
    note: note,
    transaction_type:
      differenceDiscount > 0 ? "discount" : "discount_correction",
    origin: "manual",
    posting_date: moment().format("YYYY-MM-DD"),
    value_date: moment(paymentDate).format("YYYY-MM-DD"),
  };
  const feeReqBody = {
    order_id: order_id,
    order_type: "course",
    variable_symbol: order_id,
    amount: differenceCourseFee,
    note: note,
    transaction_type:
      differenceCourseFee < 0
        ? "registration_fee"
        : "registration_fee_correction",
    origin: "manual",
    posting_date: moment().format("YYYY-MM-DD"),
    value_date: moment(paymentDate).format("YYYY-MM-DD"),
  };

  const handlePayment = async () => {
    const payments = [
      differenceCoursePrice !== 0 && coursePaymentReqBody,
      alreadyPaidDiference !== 0 && paymentReqBody,
      differenceDiscount !== 0 && discountReqBody,
      differenceCourseFee !== 0 && feeReqBody,
    ].filter(Boolean);

    try {
      if (payments.length > 0) {
        const { data } = await ZoozaApi.post("/payments", payments);
        if (res) {
          setRes([...res, ...data]);
        } else {
          setRes(data);
        }
        console.log("Success:", data);
        return true;
      } else {
        // Handle the case where all payments are false or empty
        console.log("No payments to send");
        return false;
      }
    } catch (err) {
      console.error("Fetch Error:", err);
      setError(err);
      return false;
    }
  };

  const balance_account = payments?.reduce(
    (acc, payment) =>
      get_balance(Number(acc), Number(payment.amount), Boolean(payment.amount)),
    0
  );

  return (
    <Modal
      title={t("add_payment_title")}
      isOpen={open}
      onClose={() => setOpen(false)}
      onSubmit={handleSubmit}
      submitButton={t("save__button")}
      cancelButton={t("cancel_button")}
      loading={isSubmitting}
      buttonDisabled={
        isSubmitting || buttonDisabled || alreadyPaidDiference === 0
          ? false
          : !paymentType
      }
    >
      <div style={{ display: "grid", gap: 30 }}>
        <div className="payment-modal-item">
          <div>
            <div>
              <span>{t("course_price_label")}</span>
              <InputNumber
                label=""
                setValue={setCoursePrice}
                value={coursePrice}
                nextButtonChildreen={
                  Number(course?.price) !== Number(coursePrice) && (
                    <a
                      style={{ marginLeft: 10 }}
                      className="z2"
                      onClick={() => setCoursePrice(Number(course?.price))}
                    >
                      <span className="icon-sync"></span>
                    </a>
                  )
                }
              />
            </div>

            <div className="payment-modal-item-label">
              <h4>{t("difference__label")}:</h4>
              <span>
                {convertToCurrency(
                  Number(differenceCoursePrice),
                  userData?.company.region
                )}
              </span>
            </div>
          </div>
          <div>
            <span>{t("price__registration_fee")}</span>
            <InputNumber
              label=""
              setValue={setCourseFee}
              value={courseFee}
              nextButtonChildreen={
                Number(course?.registration_fee) !== Number(courseFee) && (
                  <a
                    style={{ marginLeft: 10 }}
                    className="z2"
                    onClick={() =>
                      setCourseFee(Number(course?.registration_fee))
                    }
                  >
                    <span className="icon-sync"></span>
                  </a>
                )
              }
            />
            <div className="payment-modal-item-label">
              <h4>{t("difference__label")}:</h4>
              <span>
                {convertToCurrency(
                  Number(differenceCourseFee),
                  userData?.company.region
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="payment-modal-item">
          <div>
            <span>{t("paid_whole_course__label")}</span>
          </div>
          <div>
            <input
              type="checkbox"
              id="paid_whole_course"
              checked={paidWholeCourse}
              onChange={() => setPaidWholeCourse(!paidWholeCourse)}
              onClick={() => setPaidWholeCourse(!paidWholeCourse)}
            />
            {paidWholeCourse && (
              <span>
                {" "}
                {convertToCurrency(
                  alreadyPaidDiference,
                  userData?.company.region
                )}
              </span>
            )}
          </div>
        </div>
        {!paidWholeCourse && (
          <div className="payment-modal-item">
            <div className="payment-modal-item-label">
              <h4>{t("difference__label")}</h4>
              <span>
                {convertToCurrency(
                  Number(alreadyPaidDiference),
                  userData?.company.region
                )}
              </span>
            </div>
            <div>
              <span>{t("paid__title")}</span>

              <InputNumber
                disabled={paidWholeCourse}
                label=""
                value={
                  paidWholeCourse
                    ? coursePrice + courseFee - Number(discount)
                    : paid
                }
                setValue={setPaied}
              />
            </div>
          </div>
        )}
        <div className="payment-modal-item">
          <div>
            <span>{t("payment_method__title")}</span>
          </div>
          <fieldset className="radio_group">
            <ul>
              <li>
                <label htmlFor="CREDIT_VIA_TRANSFER">
                  <input
                    disabled={
                      alreadyPayed !== 0 && Number(alreadyPayed) >= Number(paid)
                    }
                    type="radio"
                    value="CREDIT_VIA_TRANSFER"
                    checked={paymentType === "CREDIT_VIA_TRANSFER"}
                    id="CREDIT_VIA_TRANSFER"
                    onChange={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                    onClick={() => setPaymentType("CREDIT_VIA_TRANSFER")}
                  />
                  {t("transfer__label_select")}
                </label>
              </li>
              <li>
                <label htmlFor="credit">
                  <input
                    disabled={
                      alreadyPayed !== 0 && Number(alreadyPayed) >= Number(paid)
                    }
                    type="radio"
                    name="credit"
                    value="credit"
                    checked={paymentType === "credit"}
                    id="credit"
                    onChange={() => setPaymentType("credit")}
                    onClick={() => setPaymentType("credit")}
                  />
                  {t("payment_type_cash")}
                </label>
              </li>
            </ul>
          </fieldset>
        </div>

        <div className="payment-modal-item">
          <div className="payment-modal-item-label">
            <h4>{t("difference__label")}:</h4>
            <span>
              {convertToCurrency(
                Number(differenceDiscount),
                userData?.company.region
              )}
            </span>
          </div>
          <div>
            <span>{t("discount__title")}</span>
            <InputNumber value={discount} setValue={setDiscount} label="" />
          </div>
        </div>
        <div className="payment-modal-item">
          <div>
            <span>{t("payment_receiving_date_header")}</span>
          </div>
          <div>
            <DateTimePicker
              required={true}
              date={paymentDate.toDate()}
              help={""}
              setDate={setPaymentDate}
            />
          </div>
        </div>
        <div className="payment-modal-item">
          <div>
            <span>{t("balance__title")}</span>
          </div>
          <div>
            {convertToCurrency(balance_account, userData?.company.region)}
          </div>
        </div>
      </div>
    </Modal>
  );
};
