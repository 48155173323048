import { t } from "i18next";
import React, { useContext } from "react";
import { PaymentStatus } from "../../components/payment-status";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRowListItem,
  ZoozaCardListItemValue,
  ZoozaCardListItemActions,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { convertToCurrency } from "../../services/curency-service";
import { Registration } from "../../types/registration-types";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaContext } from "../../services/zooza.context";
import { AddPaymentModal } from "../../components/add-payment-modal";
import { useParams } from "react-router-dom";
import { Payment } from "../../types/payments-types";
export const PaymentsStatusComponent = ({
  registration,
  payments,
}: {
  registration: Registration | undefined;
  payments: Payment[] | undefined;
}) => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [open, setOpen] = React.useState(false);
  const [modalType, setModalType] = React.useState<
    "discount" | "payment" | "fee" | "course_payment" | "refund"
  >("payment");
  const { id } = useParams();

  const balance_account = payments?.reduce(
    (acc: number, payment: Payment) => acc + Number(payment.amount),
    0
  );

  const registration_fee_transaction_types = [
    "registration_fee",
    "registration_fee_correction",
  ];
  const debt_transaction_types = [
    "course_payment",
    "course_payment_correction",
    "payment_schedule",
  ];

  const registration_paid = payments?.reduce((acc, payment) => {
    if (
      offline_transaction_types.includes(
        payment.transaction_type.toLowerCase()
      ) ||
      offline_transaction_types.includes(payment.transaction_type.toUpperCase())
    ) {
      return acc + Number(payment.amount);
    } else {
      return acc;
    }
  }, 0);
  const refund_transaction_types = ["refund"];
  const refund_paid = payments?.reduce((acc, payment) => {
    if (
      refund_transaction_types.includes(
        payment.transaction_type.toLowerCase()
      ) ||
      refund_transaction_types.includes(payment.transaction_type.toUpperCase())
    ) {
      return acc + Number(payment.amount);
    } else {
      return acc;
    }
  }, 0);

  const registration_owed = payments?.reduce((acc, payment) => {
    if (
      debt_transaction_types.includes(payment.transaction_type.toLowerCase()) ||
      debt_transaction_types.includes(payment.transaction_type.toUpperCase())
    ) {
      return get_balance(
        Number(acc),
        Number(payment.amount),
        Boolean(payment.amount)
      );
    } else {
      return acc;
    }
  }, 0);
  const registration_discount = payments?.reduce((acc, payment) => {
    if (
      discount_trasnaction_types.includes(
        payment.transaction_type.toLowerCase()
      ) ||
      discount_trasnaction_types.includes(
        payment.transaction_type.toUpperCase()
      )
    ) {
      return get_balance(
        Number(acc),
        Number(payment.amount),
        Boolean(payment.amount)
      );
    } else {
      return acc;
    }
  }, 0);
  const registration_fee = payments?.reduce((acc, payment) => {
    if (
      registration_fee_transaction_types.includes(
        payment.transaction_type.toLowerCase()
      ) ||
      registration_fee_transaction_types.includes(
        payment.transaction_type.toUpperCase()
      )
    ) {
      return get_balance(
        Number(acc),
        Number(payment.amount),
        Boolean(payment.amount)
      );
    } else {
      return acc;
    }
  }, 0);
  const modalHandler = (e: any) => {
    setOpen(true);
    setModalType(e.target.id);
  };

  return (
    <>
      <AddPaymentModal
        open={open}
        setOpen={setOpen}
        order_id={registration?.id || String(id) || ""}
        type={modalType}
      />

      <ZoozaCard>
        <ZoozaCardHeader icon="icon-money-plant" title="PAYMENTS STATUS" />
        <ZoozaCardRowListItem emphasize>
          <ZoozaCardListItemValue>
            <PaymentStatus
              icon
              payment_status={registration?.payment_status || ""}
            />
          </ZoozaCardListItemValue>

          <ZoozaCardListItemActions>
            <button id="payment" onClick={modalHandler} className="z2 primary">
              {t("add_payment_button")}
            </button>
          </ZoozaCardListItemActions>
        </ZoozaCardRowListItem>

        <ZoozaCardRowListItem span={2}>
          <div className="list_item_label">
            <p aria-label={t("balance__title")}>{t("balance__title")}</p>
          </div>
          <ZoozaCardListItemValue span={2}>
            <p>
              <span
                className={
                  Math.sign(balance_account || 0) === -1
                    ? "num_negative"
                    : Math.sign(balance_account || 0) === +1
                    ? "num_positive"
                    : "num_neutral"
                }
              >
                {convertToCurrency(balance_account, userData?.company.region)}
              </span>
            </p>
            <p className="text_info">
              <small>{t("amount_owed_registration_fee_paid_discounts")}</small>
            </p>
          </ZoozaCardListItemValue>
        </ZoozaCardRowListItem>
        <ZoozaCardRowListItem>
          <div className="list_item_label">
            <p aria-label={t("paid__title")}>{t("paid__title")}</p>
          </div>
          <ZoozaCardListItemValue span={2}>
            <p>
              <span
                className={
                  Math.sign(registration_paid || 0) === -1
                    ? "num_negative"
                    : Math.sign(registration_paid || 0) === +1
                    ? "num_positive"
                    : "num_neutral"
                }
              >
                {convertToCurrency(registration_paid, userData?.company.region)}
              </span>
            </p>
            <p className="text_info">
              <small>
                {t(
                  "client_account_balance_for_registration_it_is_equal_to_zero"
                )}
              </small>
            </p>
          </ZoozaCardListItemValue>
        </ZoozaCardRowListItem>
        <ZoozaCardRowListItem>
          <div className="list_item_label">
            <p aria-label={t("amount_owed")}>{t("amount_owed")}</p>
          </div>
          <ZoozaCardListItemValue>
            <p>
              <span
                className={
                  Math.sign(registration_owed || 0) === -1
                    ? "num_negative"
                    : Math.sign(registration_owed || 0) === +1
                    ? "num_positive"
                    : "num_neutral"
                }
              >
                {convertToCurrency(registration_owed, userData?.company.region)}
              </span>
            </p>
            <p className="text_info">
              <small></small>
            </p>
          </ZoozaCardListItemValue>
          <ZoozaCardListItemActions>
            <button
              id="course_payment"
              onClick={modalHandler}
              className="z2 primary"
            >
              {t("edit_payment__button")}
            </button>
          </ZoozaCardListItemActions>
        </ZoozaCardRowListItem>

        <ZoozaCardRowListItem>
          <div className="list_item_label">
            <p aria-label={t("registration_fee__title")}>
              {t("registration_fee__title")}
            </p>
          </div>
          <ZoozaCardListItemValue>
            <p>
              <span
                className={
                  Math.sign(registration_fee || 0) === -1
                    ? "num_negative"
                    : Math.sign(registration_fee || 0) === +1
                    ? "num_positive"
                    : "num_neutral"
                }
              >
                {convertToCurrency(registration_fee, userData?.company.region)}
              </span>
            </p>
            <p className="text_info">
              <small></small>
            </p>
          </ZoozaCardListItemValue>
          <ZoozaCardListItemActions>
            <button id="fee" onClick={modalHandler} className="z2 primary">
              {t("edit_discount__button")}
            </button>
          </ZoozaCardListItemActions>
        </ZoozaCardRowListItem>
        {refund_paid && (
          <ZoozaCardRowListItem>
            <div className="list_item_label">
              <p aria-label={t("registration_payments__refunded")}>
                {t("registration_payments__refunded")}
              </p>
            </div>
            {}
            <ZoozaCardListItemValue>
              <p>
                <span
                  className={
                    Math.sign(refund_paid || 0) === -1
                      ? "num_negative"
                      : Math.sign(refund_paid || 0) === +1
                      ? "num_positive"
                      : "num_neutral"
                  }
                >
                  {convertToCurrency(
                    refund_paid?.toFixed(2),
                    userData?.company.region
                  )}
                </span>
              </p>
              <p className="text_info">
                <small></small>
              </p>
            </ZoozaCardListItemValue>
          </ZoozaCardRowListItem>
        )}
        <ZoozaCardRowListItem>
          <div className="list_item_label">
            <p aria-label={t("discount__title")}>{t("discount__title")}</p>
          </div>
          <ZoozaCardListItemValue>
            <p>
              <span
                className={
                  Math.sign(registration_discount || 0) === -1
                    ? "num_negative"
                    : Math.sign(registration_discount || 0) === +1
                    ? "num_positive"
                    : "num_neutral"
                }
              >
                {convertToCurrency(
                  registration_discount,
                  userData?.company.region
                )}
              </span>
            </p>
            <p className="text_info">
              <small></small>
            </p>
          </ZoozaCardListItemValue>
          <ZoozaCardListItemActions>
            <button id="discount" onClick={modalHandler} className="z2 primary">
              {t("edit_discount__button")}
            </button>
          </ZoozaCardListItemActions>
        </ZoozaCardRowListItem>
        <ZoozaCardActions>{""}</ZoozaCardActions>
      </ZoozaCard>
    </>
  );
};
export const get_balance = (a: number, b: number, add: boolean) => {
  return add ? a + b : a - b;
};
export const offline_transaction_types = [
  "credit",
  "credit_via_transfer",
  "credit_correction",
  "cardpay",
  "unipay",
  "viamo",
  "sporopay",
  "postapay",
  "firstdataondemand",
  "vubpay",
];
export const discount_trasnaction_types = ["discount", "discount_correction"];
