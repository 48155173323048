import { t } from "i18next";
import { ZoozaApi } from "./Axios";

export const getTrainers = async () => {
  try {
    const { data } = await ZoozaApi.get(
      "/users?page_size=5000&roles=owner|member|external_member|assistant|main_member"
    );

    return data;
  } catch (err) {
    console.error("Fetch Error:", err);
  }
};

const getTranslatedEnums = () => {
  return {
    full2: t("enums__registration_types__full2"),
    open: t("enums__registration_types__open"),
    single: t("enums__registration_types__single"),
    registrations: t("global__registrations"),
    create: t("global__create"),
    dob: t("enums__extra_fields__dob"),
    slots: t("enums__extra_fields__slots"),
    full_name: t("enums__extra_fields__full_name"),
    address: t("enums__extra_fields__address"),
    business_address: t("enums__extra_fields__business_address"),
    business_id: t("enums__extra_fields__business_id"),
    business_name: t("enums__extra_fields__business_name"),
    identification_number: t("enums__extra_fields__identification_number"),
    tax_id: t("enums__extra_fields__tax_id"),
    vat: t("enums__extra_fields__vat"),
    extra_field_1: t("enums__extra_fields__extra_field_1"),
    extra_field_2: t("enums__extra_fields__extra_field_2"),
    extra_field_3: t("enums__extra_fields__extra_field_3"),
    extra_field_4: t("enums__extra_fields__extra_field_4"),
    extra_field_5: t("enums__extra_fields__extra_field_5"),
    phone: t("global__phone"),
    email: t("global__email"),
    first_name: t("global__first_name"),
    last_name: t("global__last_name"),
    canceled: t("registrations__status__canceled"),
    late: t("registrations__status__late"),
    registered: t("registrations__status__registered"),
    waitlist: t("registrations__status__waitlist"),
    pre_registered: t("registrations__status__pre_registered"),
    paid: t("enums__payment_statuses__paid"),
    unpaid: t("enums__payment_statuses__unpaid"),
    partially_paid: t("enums__payment_statuses__partially_paid"),
    overpaid: t("enums__payment_statuses__overpaid"),
    not_paid: t("enums__payment_statuses__not_paid"),
    partialy_paid: t("enums__payment_statuses__partialy_paid"),
    going: t("enums__attendance__going"),
    attended: t("enums__attendance__attended"),
    noshow: t("enums__attendance__noshow"),
    discount: t("payments__transaction_types__discount"),
    discount_correction: t("payments__transaction_types__discount_correction"),
    CREDIT: t("payments__transaction_types__CREDIT"),
    CREDIT_VIA_TRANSFER: t("payments__transaction_types__CREDIT_VIA_TRANSFER"),
    credit: t("payments__transaction_types__CREDIT"),
    credit_correction: t("payments__transaction_types__credit_correction"),
    registration_fee: t("payments__transaction_types__registration_fee"),
    registration_fee_correction: t(
      "payments__transaction_types__registration_fee_correction"
    ),
    course_payment: t("payments__transaction_types__course_payment"),
    course_payment_correction: t(
      "payments__transaction_types__course_payment_correction"
    ),
    payments: t("global__payments"),
    refund: t("payments__transaction_types__refund"),
    SPOROPAY: t("payments__transaction_types__SPOROPAY"),
    TATRAPAY: t("payments__transaction_types__TATRAPAY"),
    UNIPAY: t("payments__transaction_types__UNIPAY"),
    VIAMO: t("payments__transaction_types__VIAMO"),
    VUBPAY: t("payments__transaction_types__VUBPAY"),
    FIRSTDATAONDEMAND: t("payments__transaction_types__FIRSTDATAONDEMAND"),
    CARDPAY: t("payments__transaction_types__CARDPAY"),
    calendar: t("calendar__calendar__title"),
  };
};

export const msg = (enum_string: string | undefined) => {
  const enums = getTranslatedEnums();
  if (!enums[enum_string as keyof typeof enums]) {
    if (enum_string) {
      console.log(`%cenum ${enum_string} not found`, "color: orange");
    }

    return enum_string;
  } else {
    return enums[enum_string as keyof typeof enums];
  }
};
