import React, { useContext } from "react";
import { Input } from "../../components/input";
import { ZoozaSelect } from "../../components/inputs";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import {
  CreateRegistrationContextTypes,
  Place,
  Room,
  ZoozaContextTypes,
} from "../../types/types";
import { CreateRegistrationContext } from "./create-registration-context";
import { ZoozaContext } from "../../services/zooza.context";
import { Course } from "../../types/courses-types";
import { useSearchParams } from "react-router-dom";
import { msg } from "../../services/common-functions";
import { useTranslation } from "react-i18next";
type ScheduleCard = {
  progress: number;
  progressing: (e: any) => void;
};
type GroupedOptions = {
  [key: string]: Course[];
};

export const ScheduleCard = ({ progress, progressing }: ScheduleCard) => {
  const {
    pid,
    setPid,
    setScheduleName,
    scheduleName,
    course,
    setCourse,
    setMember,
    member,
    place,
  } = useContext<CreateRegistrationContextTypes>(CreateRegistrationContext);
  const { places, courses, members } =
    useContext<ZoozaContextTypes>(ZoozaContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const groupedCourses = courses
    ?.filter((course) => course.archive === false)
    .reduce((acc: GroupedOptions, option) => {
      const accOptionGroup = acc[option.registration_type] || [];

      return {
        ...acc,
        [option.registration_type]: [...accOptionGroup, option],
      };
    }, {});
  const { t } = useTranslation();
  return (
    <ZoozaCard isDisabeld={progress !== 1}>
      <ZoozaCardHeader title={t("schedule__title")} />
      {progress === 1 && (
        <>
          <ZoozaCardRow>
            <ZoozaCardCell>
              <span>{t("schedule__name_title")}</span>
              <Input
                onChange={(e) => setScheduleName?.(e.target.value)}
                value={scheduleName}
                placeholder={t("schedule__name_title")}
                type="text"
              />
            </ZoozaCardCell>
            <ZoozaCardCell>
              <span>
                {t("place__title")} <em>*</em>
              </span>
              <ZoozaSelect
                param={"place"}
                onChange={(e: any) => {
                  setPid?.(e.target.value);
                }}
                value={pid || searchParams.get("place") || ""}
              >
                <option value="" disabled>
                  {t("select_place_selector")}
                </option>
                {places?.map((place: Place) => (
                  <optgroup key={place.id} label={place.name}>
                    <option value={place.pid}>{place.name}</option>
                    {place.rooms?.map((room: Room) => (
                      <option key={room.id} value={room.pid}>
                        {room.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </ZoozaSelect>
            </ZoozaCardCell>
            <ZoozaCardCell>
              <span>
                {t("course_title")} <em>*</em>
              </span>
              <ZoozaSelect
                param="course"
                value={course?.id || ""}
                onChange={(e: any) =>
                  setCourse?.(
                    courses?.find(
                      (course) => course.id === Number(e.target.value)
                    )
                  )
                }
              >
                <option value="" disabled>
                  {t("select_course_selector")}
                </option>
                {Object.keys(groupedCourses || []).map((option) => (
                  <optgroup key={option} label={msg(option)}>
                    {groupedCourses?.[option].map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </ZoozaSelect>
            </ZoozaCardCell>
            <ZoozaCardCell>
              <span>
                {t("member_title")} <em>*</em>
              </span>
              <ZoozaSelect
                param="member"
                value={member?.id || ""}
                onChange={(e: any) =>
                  setMember?.(
                    members?.find(
                      (member) => member.id === Number(e.target.value)
                    )
                  )
                }
                disabled={members?.length === 0}
              >
                <option value="" disabled>
                  {t("select_member_selector")}
                </option>
                {members?.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.first_name} {member.last_name}
                  </option>
                ))}
              </ZoozaSelect>
            </ZoozaCardCell>
          </ZoozaCardRow>
          <ZoozaCardActions>
            <button
              onClick={progressing}
              id="progress"
              value={2}
              className="z2 primary"
              disabled={!course || !place || !member}
            >
              {t("continue__button")}
            </button>
          </ZoozaCardActions>
        </>
      )}
    </ZoozaCard>
  );
};
