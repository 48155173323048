import React, { useContext, useEffect, useState } from "react";
import { ZoozaApi } from "../services/Axios";
import { useParams } from "react-router-dom";

import "../styles/attendance.css";
import "moment/min/locales";

import { ZoozaContext } from "../services/zooza.context";
import { ZoozaContextTypes } from "../types/types";
import { msg } from "../services/common-functions";
import { useTranslation } from "react-i18next";

const InputStyledRadioGroup = ({
  status,
  registration_id,
  user_id,
  getAttendance,
}: {
  status: string | null | undefined;
  registration_id: number | null | undefined;
  user_id: number | null | undefined;
  getAttendance: () => void;
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const attendance_options = [
    {
      label: t("enums__attendance__going"),
      value: "going",
    },
    {
      label: t("enums__attendance__attended"),
      value: "attended",
    },
    {
      label: t("enums__attendance__canceled"),
      value: "canceled",
    },
    {
      label: t("enums__attendance__noshow"),
      value: "noshow",
    },
  ];
  const { id } = useParams();
  const [varStatus, setStatus] = useState<string | null | undefined>(status);
  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLoading(true);
    e.preventDefault();
    if (varStatus !== e.currentTarget.value) {
      try {
        const { data } = await ZoozaApi.put(
          `/registrations/${registration_id}`,
          {
            action: "set_attendance",
            attendance: e.currentTarget.value,
            event_id: id,
            user_id: user_id,
          }
        );

        if (data) {
          getAttendance();
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    setStatus(status);
  }, [status]);
  return (
    <div className={"styled_radio_group"}>
      {attendance_options.map((e, index) => (
        <button
          key={index}
          className={`styled_radio_group button ${
            varStatus === e.value ? "active" : ""
          }
          ${loading ? "loading" : ""}`}
          value={e.value}
          onClick={onClick}
        >
          {e.label}
        </button>
      ))}
    </div>
  );
};

export const AttendanceManager = ({
  status,
  registration_id,
  getAttendance,
  user_id,
}: {
  status: string | null | undefined;
  registration_id?: number | null | undefined;
  getAttendance: () => void;
  user_id: number | null | undefined;
}) => {
  return (
    <div className="attendance_manager">
      <p className="attendance_label">
        <em
          className={
            status === "going" || status === "attended"
              ? "bullet_green"
              : status === "canceled"
              ? "bullet_orange"
              : status === "noshow"
              ? "bullet_red"
              : ""
          }
        ></em>
        <strong>{" " + msg(status || "")}</strong>
      </p>
      <div className="select">
        <div>
          <InputStyledRadioGroup
            registration_id={registration_id}
            status={status}
            user_id={user_id}
            getAttendance={() => getAttendance()}
          />
        </div>
      </div>
      <div className="attendance_actions"></div>
    </div>
  );
};
