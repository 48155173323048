export interface PaymentsApiResponse {
  total: number;
  page: number;
  page_size: number;
  settings: Settings;
  data: Payment[];
}

export interface Payment {
  id: number;
  order_id: number;
  order_type: string;
  variable_symbol: number;
  posting_date: string;
  value_date: string;
  due_date: null;
  payers_reference: string;
  payers_bank_reference: string;
  amount: number;
  currency: string;
  transaction_type: string;
  note: string;
  information_for_beneficiary: string;
  bic: string;
  payers_iban: string;
  origin: string;
  created_by: string;
  modified_by: string;
  created: string;
  modified: string;
  color: string;
  status: string;
  provider: string;
  registrations: Registrations;
}

export interface Registrations {
  id: number;
  company_id: number;
  course_id: number;
  schedule_id: number;
  event_id: number;
  user_id: number;
  slots: number;
  billing_period_id: number;
  parent: number;
  total_events: number;
  status: string;
  business_order: boolean;
  created: string;
  updated: string;
  note: string;
  company_note: string;
  public_note: null;
  payment_method: string;
  notify: boolean;
  paid: number;
  payment_status: string;
  value: number;
  preferred_payment_schedule: number;
  __users__full_name: string;
  __users__phone: string;
  __users__email: string;
  __calc__debt: number;
  __calc__paid: number;
  __calc__balance: number;
  notify_about_upcoming_events: boolean;
  notify_alternate_email: null;
  notify_alternate_email_only: boolean;
  guest_id: number;
  __calc__guest_email: null;
  __calc__guest_user_id: number;
  __calc__guest_notify: boolean;
  attendance_management: string;
  extra_replacements_limit: number;
  payments_managed_by: number;
  iban: null;
  swift: null;
  invoice_profile: number;
}

export interface Settings {
  order_id: number;
  order_type: string;
  month: null;
  transaction_type: null;
  provider: string;
}

export interface InvoiceProfilesApiResponse {
  total: number;
  page: number;
  page_size: number;
  settings: any[];
  data: InvoiceProfiles[];
}

export interface InvoiceProfiles {
  id: number;
  company_id: number;
  zip: number;
  ico: number;
  dic: number;
  street: string;
  city: string;
  state: string;
  phone: string;
  created: string;
  updated: string;
  icdph: string;
  vatpayer: boolean;
  viewpayer: boolean;
  name: string;
  email: string;
  invoice_no: number;
  iban: null | string;
  swift: null | string;
  invoice_profiles_courses?: InvoiceProfilesCourse[];
}

export interface InvoiceProfilesCourse {
  id: number;
  company_id: number;
  course_id: number;
  invoice_profile_id: number;
  created: string;
}

// Generated by https://quicktype.io

export interface BillingPeriodApiResponse {
  total: number;
  page: number;
  page_size: number;
  settings: any[];
  data: BilingPeriods[];
}

export interface BilingPeriods {
  id: number;
  company_id: number;
  name: string;
  active: boolean;
  created: string;
  modified: string;
}

export type InvoiceAPIResponse = {
  total: number;
  page: number;
  page_size: number;
  settings: Settings;
  data: Invoice[];
};

export type Invoice = {
  id: number;
  invoice_no: number;
  order_id: number;
  order_type: string;
  company_id: number;
  faktury_online_code: null | string;
  invoice_data: InvoiceData | null;
  box_id: number;
  faktury_online_error_code: number;
  options: Options;
  created: string;
  updated: string;
};

export type InvoiceData = {
  d: D;
  o: O;
  f: F;
  p: P[];
  key: string;
  email: string;
  apitest: number;
};

export type D = {
  d_name: string;
  d_street: string;
  d_city: string;
  d_state: string;
  d_zip: number;
  d_ico: number;
  d_dic: number;
  d_icdph: string;
  d_vatpayer: number;
  d_viewpayer: number;
  d_iban: string;
  d_email: string;
  d_web: string;
  d_phone: string;
};

export type F = {
  f_number: number;
  f_vs: number;
  f_qr: number;
  f_paid: string;
  f_currency: string;
  f_language: string;
  f_date_issue: string;
  f_date_delivery: string;
  f_payment: string;
};

export type O = {
  o_name: string;
  o_street: string;
  o_email: string;
  o_phone: string;
};

export type P = {
  p_text: string;
  p_quantity: number;
  p_unit: string;
  p_vat: string;
  p_pricevat: number;
};

export type Options = {
  date: string;
  text: string;
  payment_method?: string;
};
export const paymentTypes: readonly string[] = [
  "cardpay",
  "credit",
  "CREDIT_VIA_TRANSFER",
  "SPOROPAY",
  "TATRAPAY",
  "VIAMO",
  "VUBPAY",
  "zooza_cash",
  "CREDIT",
  "credit_via_transfer",
  "credit_correction",
  "refund",
  "payment_schedule",
  "payment_schedule_correction",
  "zooza_online_transfer_percentage",
];
export const discountTypes: readonly string[] = [
  "discount",
  "discount_correction",
];
export const coursePaymentTypes: readonly string[] = [
  "course_payment",
  "course_payment_correction",
];
export const feePaymentTypes: readonly string[] = [
  "registration_fee",
  "registration_fee_correction",
];

export type PaymentTransactionTypes = {
  transaction_type: (typeof paymentTypes)[number];
};
export type DiscountTransactionTypes = {
  transaction_type: (typeof discountTypes)[number];
};
export type CoursePaymentTransactionTypes = {
  transaction_type: (typeof coursePaymentTypes)[number];
};
export type FeePaymentTransactionTypes = {
  transaction_type: (typeof feePaymentTypes)[number];
};
