import React, { useContext, useEffect, useState } from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { Registration, Schedule } from "../../types/registration-types";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import { ZoozaApi } from "../../services/Axios";

import { BillingPeriodApiResponse } from "../../types/payments-types";
import moment from "moment";
import { lang } from "../../components/language-selector";
import { useTranslation } from "react-i18next";

export const GroupComponent = ({
  registration,
  schedule,
}: {
  registration: Registration | undefined;
  schedule: Schedule | undefined;
}) => {
  const { t } = useTranslation();
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [billingPeriods, setBillingPeriods] =
    useState<BillingPeriodApiResponse>();

  const getBillingPeriod = async () => {
    const { data: billing_periods } = await ZoozaApi.get("billing_periods");

    setBillingPeriods(billing_periods);
  };
  useEffect(() => {
    getBillingPeriod();
  }, []);

  const course = courses?.find((c) => c.id === registration?.course_id);
  const billing_period = billingPeriods?.data?.find(
    (bp) => bp.id === registration?.billing_period_id
  );

  return (
    <ZoozaCard>
      <ZoozaCardHeader icon="icon-transform" title={t("title_group")} />
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("course_name__title")}>
            {t("course_name__title")}
          </span>
          <p>{course?.name}</p>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("group_name")}>{t("group_name")}</span>
          <p>
            {schedule?.name
              ? schedule.name
              : schedule?.events?.length
              ? moment(schedule?.events?.[0].date).locale(lang).format("LLLL")
              : t("global__lead_basket")}
          </p>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("invoice_profile")}>{t("invoice_profile")}</span>
          <p>{billing_period?.name}</p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("place_name")}>{t("place_name")}</span>
          <p>{registration?.business_order}</p>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardActions>
        {" "}
        {/* <button className="z2">{t("move_to")}</button> */}
      </ZoozaCardActions>
    </ZoozaCard>
  );
};
