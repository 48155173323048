import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Member, Place, ZoozaContextTypes } from "../types/types";
import { AttendanceEvent, Schedule } from "../types/registration-types";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardCell,
  ZoozaCardHeader,
  ZoozaCardRow,
} from "../components/zooza-card-components";
import { ZoozaContext } from "../services/zooza.context";
import { ZoozaApi } from "../services/Axios";
import { Input } from "../components/input";
import moment from "moment";
import DatePicker from "react-datepicker";
import { t, use } from "i18next";
import { EventDetail, ScheduleDetailResponse } from "../types/schedue-type";
import { AttendanceManager } from "../components/attendance-manager";
import { Portal } from "react-overlays";
import { ReactComponent as Loader } from "../assets/img/loader.svg";
import { ZoozaSelect } from "../components/inputs";
import { AddPaymentModal, InputNumber } from "../components/add-payment-modal";
import { msg } from "../services/common-functions";
export const EventPage = () => {
  const {
    setLoading,

    places = [],
    courses,
    members,
  } = useContext<ZoozaContextTypes>(ZoozaContext);

  const { id } = useParams();
  const location = useLocation();
  const [event, setEvent] = useState<EventDetail>();
  const [trainer, setTrainer] = useState<Member[]>();
  const [attendance, setAttendance] = useState<AttendanceEvent[]>();
  const [schedules, setSchedules] = useState<ScheduleDetailResponse>();
  const [open, setOpen] = React.useState(false);
  useContext<ZoozaContextTypes>(ZoozaContext);
  //Find place and course
  const eventCourse = courses?.find((course) => course.id === event?.course_id);

  //Loading
  const [smallLoading, setSmallLoading] = useState(false);
  const [successSave, setSuccessSave] = useState("");

  //Changing termin
  const [place, setPlace] = useState<any>(
    places?.find((p) => p.id === event?.place_id)
  );
  const [member, setMember] = useState<any>(
    members?.find((m) => m.id === event?.trainer_id)
  );
  const [inputNumber, setInputNumber] = useState<number | undefined>();

  const [eventDate, setEventDate] = useState<any>(
    moment(event?.date).format("YYYY-MM-DD HH:mm:ss")
  );

  //Checking role
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);

  const [role, setRole] = useState<any>();

  useEffect(() => {
    const role = userData?.user.role;
    setRole(role);
  }, []);

  const getEvent = async () => {
    setLoading?.(true);
    try {
      const { data } = await ZoozaApi.get(`events/${id}`);
      setEvent(data);
      setLoading?.(false);
    } catch (error) {
      console.error(error);
      setLoading?.(false);
    }
  };

  //CHange place and member
  const onPlaceChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const place = places?.find((place) => place.id === Number(e.target.value));
    setPlace(place);
  };

  const onMemberChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    const member = members?.find(
      (member) => member.id === Number(e.target.value)
    );
    setMember(member);
  };

  const onDateChange = (date: any) => {
    setEventDate(date);
  };

  const onEventTimeFromChange = (date: any) => {
    // setTimeEventFrom(date);
    setEventDate(date);
  };

  const getTrainers = async () => {
    try {
      const { data } = await ZoozaApi.get(
        "/users?page_size=5000&roles=owner|member|external_member|assistant|main_member"
      );
      setTrainer(data.data);
    } catch (err) {
      console.error("Fetch Error:", err);
    }
  };
  const getAttendance = async () => {
    try {
      const { data } = await ZoozaApi.get(`/attendance?event_id=${id}`);
      setAttendance(data);
    } catch (err) {
      console.error("Fetch Error:", err);
      throw err;
    }
  };
  const getSchedule = async () => {
    try {
      const { data } = await ZoozaApi.get(`/schedules/${event?.schedule_id}`);
      setSchedules(data);
    } catch (err) {
      console.error("Fetch Error:", err);
      throw err;
    }
  };

  //Save Changes on Event
  const saveEvent = async () => {
    try {
      setSmallLoading(true);

      const { data } = await ZoozaApi.put(`/events/${event?.id}`, {
        date: moment(eventDate).format("YYYY-MM-DD HH:mm:ss"),
        trainer_id: member.id,
        event_name: null,
        place_id: place.id,
        room_id: event?.room_id,
        duration: Number(inputNumber),
        time_string:
          moment(eventDate).hours() * 60 + moment(event?.date).minutes(),
      });
    } catch (error) {
      console.error(error);
      setSmallLoading(false);
      setSuccessSave("Nastala chyba, skúste to ešte raz.");
    } finally {
      setSmallLoading(false);
      setSuccessSave("Zmeny boli uložené.");
      setTimeout(() => {
        setSuccessSave("");
      }, 2000);
    }
  };

  useEffect(() => {
    setPlace(places?.find((place) => place.id === event?.place_id));
    setMember(members?.find((member) => member.id === event?.trainer_id));
    setEventDate(moment(event?.date).toDate());
    setInputNumber(event?.duration);
  }, [event]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        Promise.all([getEvent(), getTrainers(), getAttendance()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    if (event?.schedule_id) {
      getSchedule();
    }
  }, [event?.schedule_id]);

  const upComingEvent = schedules?.events?.find((e) =>
    moment(e.date).isAfter(event?.date)
  );
  const pastEvent = schedules?.events?.find((e) =>
    moment(e.date).isBefore(event?.date)
  );

  return (
    <div className="z2 app_page_layout">
      <div className="z2 app_page_toolbar">
        <Link
          to={
            pastEvent !== undefined
              ? `/calendar/${pastEvent?.id}`
              : location.pathname
          }
          role="button"
          className={"z2 " + (pastEvent === undefined ? " disabled" : "")}
        >
          {t("past_event__link_label") +
            " " +
            moment(pastEvent?.date).format("DD.MM.YYYY")}
        </Link>
        <Link
          to={
            upComingEvent !== undefined
              ? `/calendar/${upComingEvent?.id}`
              : location.pathname
          }
          role="button"
          className={"z2" + (upComingEvent === undefined ? " disabled" : "")}
        >
          {t("next_event__link_label") +
            " " +
            moment(upComingEvent?.date).format("DD.MM.YYYY")}
        </Link>
      </div>
      <div className="app_page_column span_1"></div>
      <ZoozaCard>
        <ZoozaCardHeader
          title={"global__date_time"}
          icon="icon-transform"
        ></ZoozaCardHeader>
        <ZoozaCardRow>
          <ZoozaCardCell>
            <span aria-label={t("date__title")}>{t("date__title")}</span>
            <DatePicker
              selected={moment(eventDate).toDate()}
              onChange={(date) => {
                onDateChange(date);
              }}
              dateFormat="yyyy-MM-dd"
              minDate={role === "owner" ? null : moment().toDate()}
              popperContainer={CalendarContainer}
              customInput={
                <Input
                  value={moment(event?.date).format("HH:mm") || ""}
                  type={"text"}
                  placeholder={moment(event?.date).format("YYYY-MM-DD")}
                  id={"datepicker-input"}
                />
              }
            />
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("time_from__title")}>
              {t("time_from__title")}
            </span>
            <DatePicker
              // selected={timeEventFrom ? moment(timeEventFrom, 'HH:mm').toDate() : null}
              selected={moment(eventDate, "HH:mm").toDate()}
              showTimeSelect
              onChange={(date) => {
                onDateChange(date);
              }}
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              popperContainer={CalendarContainer}
              customInput={
                <Input
                  value={moment(event?.date).format("HH:mm") || ""}
                  type={"text"}
                  placeholder={moment(event?.date).format("HH:mm")}
                  id={"datepicker-input"}
                />
              }
            />
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("global__duration")}>
              {t("global__duration")} (min)
            </span>
            <InputNumber
              label={false}
              format={0}
              stepper={5}
              value={inputNumber}
              setValue={setInputNumber}
            ></InputNumber>
          </ZoozaCardCell>
        </ZoozaCardRow>
        <ZoozaCardRow>
          <ZoozaCardCell>
            <span aria-label={t("place__title")}>{t("place__title")}</span>
            <ZoozaSelect param="" onChange={onPlaceChange} value={place?.id}>
              {places?.map((place: Place) => (
                <option key={place.id} value={place.id}>
                  {place.name}
                </option>
              ))}
            </ZoozaSelect>
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("trainer_name__title")}>
              {t("trainer_name__title")}
            </span>
            <ZoozaSelect value={member?.id} param="" onChange={onMemberChange}>
              {members?.map((member) => (
                <option key={member.id} value={member.id}>
                  {member.first_name + " " + member.last_name}
                </option>
              ))}
            </ZoozaSelect>
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("course_name__title")}>
              {t("course_name__title")}
            </span>
            <p>{eventCourse?.name}</p>
          </ZoozaCardCell>
        </ZoozaCardRow>
        <ZoozaCardActions>
          <button
            className="z2 primary"
            onClick={saveEvent}
            disabled={smallLoading}
          >
            {smallLoading ? (
              <Loader className="spinner-button"></Loader>
            ) : (
              t("save__button")
            )}
          </button>
          <h5>{successSave}</h5>
        </ZoozaCardActions>
      </ZoozaCard>

      <ZoozaCard>
        <ZoozaCardHeader
          title={"DOCHÁDZKA"}
          icon="icon-tick-2"
        ></ZoozaCardHeader>

        {attendance?.map((a) => (
          <>
            <ZoozaCardRow>
              <ZoozaCardCell>
                <span aria-label={t("client_name__title")}>
                  {t("client_name__title")}
                </span>
                <Link className="z2" to={`/registrations/${a.registration_id}`}>
                  {a.full_name}
                  <br />
                  {a.email}
                  <br />
                  {a.phone}
                </Link>
              </ZoozaCardCell>

              <ZoozaCardCell>
                <span aria-label={t("payment__title")}>
                  {t("payment__title")}
                </span>
                {msg(a.payment_status)}

                <div>
                  <button onClick={() => setOpen(true)} className="z2 primary">
                    {t("add_payment_button")}
                  </button>
                </div>
              </ZoozaCardCell>
              <ZoozaCardCell>
                <span aria-label={t("global__attendance")}>
                  {t("global__attendance")}
                </span>
                <AttendanceManager
                  registration_id={a?.registration_id}
                  status={a.attendance}
                  user_id={a?.user_id}
                  getAttendance={() => getAttendance()}
                />
              </ZoozaCardCell>
            </ZoozaCardRow>
            <ZoozaCardRow>
              {Object.keys(a?.extra_fields).map((key) => {
                return a?.extra_fields[key as any]?.value !== "" ? (
                  <ZoozaCardCell key={key}>
                    <span aria-label={key}>
                      {a.extra_fields[key as any].custom_label
                        ? a.extra_fields[key as any].custom_label
                        : key}
                    </span>
                    {msg(a?.extra_fields[key as any]?.value)}
                  </ZoozaCardCell>
                ) : null;
              })}
            </ZoozaCardRow>
          </>
        ))}

        <ZoozaCardActions>{""}</ZoozaCardActions>
      </ZoozaCard>
      <EventPageNotes event={event} />
      {attendance?.map((a) => (
        <div key={a.registration_id}>
          <AddPaymentModal
            open={open}
            setOpen={setOpen}
            order_id={a.registration_id}
            type="payment"
          />
        </div>
      ))}
    </div>
  );
};

const EventPageNotes = ({ event }: { event: EventDetail | undefined }) => {
  const [notes, setNotes] = useState({
    summary_public: event?.summary_public,
    summary: event?.summary,
  });
  const [isEditing, setIsEditing] = useState({
    summary_public: false,
    summary: false,
  });

  const onNoteChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const id = e.currentTarget.id;
    setNotes({ ...notes, [id]: e.currentTarget.value });
  };
  const saveNote = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.id;

    setIsEditing({ ...isEditing, [id]: false });
    try {
      const { data } = await ZoozaApi.put(`events/${event?.id}`, {
        [id]: notes[id as keyof typeof notes],
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    setNotes({
      summary_public: event?.summary_public,
      summary: event?.summary,
    });
  }, [event]);
  return (
    <ZoozaCard>
      <ZoozaCardHeader
        title={t("notes_title")}
        icon="icon-pencil-3"
      ></ZoozaCardHeader>
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span>{t("public_note__title")}</span>
          {notes.summary_public && !isEditing.summary_public && (
            <blockquote
              onClick={() =>
                setIsEditing({ ...isEditing, summary_public: true })
              }
            >
              {notes.summary_public}
            </blockquote>
          )}
          {isEditing.summary_public && (
            <div>
              <textarea
                onChange={onNoteChange}
                name="summary_public"
                id="summary_public"
                value={notes.summary_public || ""}
              />
              <button onClick={saveNote} id="summary_public" className="z2">
                {t("save__button")}
              </button>
              <button
                onClick={() =>
                  setIsEditing({ ...isEditing, summary_public: false })
                }
                className="z2"
              >
                {t("cancel_button")}
              </button>
            </div>
          )}
        </ZoozaCardCell>
      </ZoozaCardRow>

      <ZoozaCardRow>
        <ZoozaCardCell>
          <span>{t("summary_note_title")}</span>
          {notes.summary && !isEditing.summary && (
            <blockquote
              onClick={() => setIsEditing({ ...isEditing, summary: true })}
            >
              {notes.summary}
            </blockquote>
          )}
          {isEditing.summary && (
            <div>
              <textarea
                onChange={onNoteChange}
                name="summary"
                id="summary"
                value={notes.summary || ""}
              />
              <button onClick={saveNote} id="summary" className="z2">
                {t("save__button")}
              </button>
              <button
                onClick={() => setIsEditing({ ...isEditing, summary: false })}
                className="z2"
              >
                {t("cancel_button")}
              </button>
            </div>
          )}
        </ZoozaCardCell>
      </ZoozaCardRow>

      <ZoozaCardActions>
        <a
          onClick={() => setIsEditing({ ...isEditing, summary_public: true })}
          role="button"
          className="z2 primary"
        >
          {t("add_summary_public_note")}
        </a>
        <a
          onClick={() => setIsEditing({ ...isEditing, summary: true })}
          role="button"
          className="z2 primary"
        >
          {t("add_summary_note__button")}
        </a>
      </ZoozaCardActions>
    </ZoozaCard>
  );
};

const CalendarContainer = ({ children }: ReactNode | any) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};
