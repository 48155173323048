import React, { ReactNode } from "react";

type ZoozaCardProps = {
  children: ReactNode;
  emphasize?: boolean;
  span?: number;
  isDisabeld?: boolean;
};
export const ZoozaCard: React.FC<ZoozaCardProps> = ({
  children,
  span,
  isDisabeld,
}) => {
  return (
    <section
      className={
        "z2 card" +
        (span ? ` span_${span}` : "") +
        (isDisabeld ? " disabled" : "")
      }
    >
      {children}
    </section>
  );
};

export const ZoozaCardHeader = ({
  title,
  icon,
}: {
  title: string;
  icon?: string;
}) => {
  return (
    <div className="card_header">
      <em className={icon + " icon_large"}></em>
      <h3 style={{ whiteSpace: "nowrap" }}>{title}</h3>
    </div>
  );
};
export const ZoozaCardRow: React.FC<ZoozaCardProps> = ({ children }) => {
  return <div className="card_row">{children}</div>;
};
export const ZoozaCardCell: React.FC<ZoozaCardProps> = ({ children }) => {
  return <div className={"card_cell"}>{children}</div>;
};
export const ZoozaCardActions: React.FC<ZoozaCardProps> = ({ children }) => {
  return <div className="card_actions">{children}</div>;
};

export const ZoozaCardRowListItem: React.FC<ZoozaCardProps> = ({
  children,
  emphasize,
  span,
}) => {
  return (
    <div
      className={
        "card_row_list_item" +
        (emphasize === true ? " emphasize" : "") +
        (span ? ` span_${span}` : "")
      }
    >
      {children}
    </div>
  );
};
export const ZoozaCardListItemActions: React.FC<ZoozaCardProps> = ({
  children,
}) => {
  return <div className="list_item_actions">{children}</div>;
};
export const ZoozaCardListItemValue: React.FC<ZoozaCardProps> = ({
  children,
  span,
}) => {
  return (
    <div className={`list_item_value ${span ? "span_" + span : ""} `}>
      {children}
    </div>
  );
};
