import React, { useState } from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import {
  Registration,
  RegistrationExtraFields,
} from "../../types/registration-types";
import { Course, ExtraField } from "../../types/courses-types";
import { ZoozaApi } from "../../services/Axios";
import { msg } from "../../services/common-functions";
import { LoadingButton } from "../../components/loading-button";
import { useTranslation } from "react-i18next";

export const ExtraFieldComponent = ({
  extra_fields,
  course,
}: {
  extra_fields: RegistrationExtraFields | undefined;
  course: Course | undefined;
  registration: Registration | undefined;
}) => {
  const { t } = useTranslation();
  const extra_fields_keys = course?.extra_fields
    ?.filter((field) => field.active === true)
    .map((field) => field);

  const initial_visible = (extra_fields_keys || []).reduce((acc, field) => {
    return {
      ...acc,
      [field.column_name]: false,
    };
  }, {});

  const [visible, setVisible] = useState<any>(initial_visible);

  return (
    <ZoozaCard>
      <ZoozaCardHeader title={t("title_extra_fields")} />

      {extra_fields_keys?.map((field) => {
        return (
          <ExtraFieldRow
            value={extra_fields?.[field.column_name]}
            field={field}
            init_visible={visible?.[field.column_name]}
            id={extra_fields?.id}
          />
        );
      })}
      <ZoozaCardActions>{""}</ZoozaCardActions>
    </ZoozaCard>
  );
};

const ExtraFieldRow = ({
  value,
  field,
  init_visible,
  id,
}: {
  value: any;
  field: ExtraField;
  init_visible: boolean;
  id: number | undefined;
}) => {
  const [visible, setVisible] = useState(init_visible);
  const [extra_fields_value, setExtraFieldValue] = useState<any>(value);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await ZoozaApi.put(`/extra-fields/${id}`, {
      column: field.column_name,
      value: extra_fields_value,
    });
    setVisible(!visible);
    setLoading(false);
  };
  const onEdit = () => {
    setVisible(!visible);
  };
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setExtraFieldValue(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <ZoozaCardRow key={field.id}>
      <ZoozaCardCell>
        <span aria-label={String(field?.column_name)}>
          {field?.name || msg(field.column_name)}
        </span>
        <div className="z2">
          {!visible && <p onClick={onEdit}>{extra_fields_value}</p>}
          {visible && (
            <textarea
              name={field.column_name}
              id={String(field.id)}
              value={extra_fields_value}
              onChange={onChange}
            />
          )}
          {!visible && (
            <button
              id={field.column_name}
              onClick={onEdit}
              className="z2 primary"
            >
              {t("edit_button")}
            </button>
          )}
          {visible && (
            <>
              <LoadingButton
                name={t("save__button")}
                loading={loading}
                id={field.column_name}
                class="z2 primary"
                primary
                onClick={onSubmit}
              ></LoadingButton>
              <button className="z2" id={field.column_name} onClick={onEdit}>
                {" "}
                {t("cancel_button")}{" "}
              </button>
            </>
          )}
        </div>
      </ZoozaCardCell>
    </ZoozaCardRow>
  );
};
