import React from "react";
import { useTranslation } from "react-i18next";
type ZoozaTableProps = {
  children: React.ReactNode;
  disabled?: boolean;
  style?: React.CSSProperties;
};
type ZoozaTableSortingOptionsProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  options?: { value: string; label: string }[];
};
type ZoozaTablePaginationProps = {
  page?: number;
  total?: number;
  setPage?: (page: number) => void;
};

export const ZoozaTable: React.FC<ZoozaTableProps> = ({
  children,
  disabled,
}) => {
  return (
    <div className={"z2 results_table" + (disabled ? " disabled" : "")}>
      {children}
    </div>
  );
};
export const ZoozaTableHeader: React.FC<ZoozaTableProps> = ({ children }) => {
  return <div className="results_table_header">{children}</div>;
};

export const ZoozaTableBody: React.FC<ZoozaTableProps> = ({ children }) => {
  return <div className="results_table_body">{children}</div>;
};
export const ZoozaTableRow: React.FC<ZoozaTableProps> = ({
  children,
  style,
}) => {
  return (
    <div style={style} className="results_table_item">
      {children}
    </div>
  );
};
export const ZoozaTableRowItem: React.FC<ZoozaTableProps> = ({
  children,
  style,
}) => {
  return (
    <div style={style} className="table_item_row">
      {children}
    </div>
  );
};
export const ZoozaTableCell: React.FC<{
  children: React.ReactNode;
  span?: string;
}> = ({ children, span = "1" }) => {
  return <div className={`table_item_cell span_${span}`}>{children}</div>;
};
export const ZoozaTableSortingOptions: React.FC<
  ZoozaTableSortingOptionsProps
> = ({ value, onChange, options }) => {
  const { t } = useTranslation();
  return (
    <div className="sorting_options">
      <select value={value} onChange={onChange} className="z2">
        {options?.map((option, index) => (
          <option value={option.value} key={index}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ZoozaTablePagination: React.FC<ZoozaTablePaginationProps> = ({
  page,
  total = 0,
  setPage,
}) => {
  const pages = Math.ceil(total / 20);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= pages) {
      setPage?.(newPage);
    }
  };

  const renderPageNumbers = () => {
    const visiblePages = 5;
    const pageNumbers = [];

    if (pages <= visiblePages) {
      for (let i = 1; i <= pages; i++) {
        pageNumbers.push(
          <li key={i} className={i === page ? "active" : ""}>
            <a href="#" onClick={() => handlePageChange(i)}>
              {i}
            </a>
          </li>
        );
      }
    } else {
      const start = Math.max(1, Number(page) - Math.floor(visiblePages / 2));
      const end = Math.min(pages, start + visiblePages - 1);

      if (start > 1) {
        pageNumbers.push(
          <li key={1}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(1);
              }}
            >
              1
            </a>
          </li>
        );
        if (start > 2) {
          pageNumbers.push(<li key="ellipsis-start">...</li>);
        }
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(
          <li key={i} className={i === page ? "active" : ""}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(i);
              }}
            >
              {i}
            </a>
          </li>
        );
      }

      if (end < pages) {
        if (end < pages - 1) {
          pageNumbers.push(<li key="ellipsis-end">...</li>);
        }
        pageNumbers.push(
          <li key={pages}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handlePageChange(pages);
              }}
            >
              {pages}
            </a>
          </li>
        );
      }
    }

    return pageNumbers;
  };
  const { t } = useTranslation();
  return (
    <div className="results_table_footer">
      <div className="pager">
        <ul className="pages">{renderPageNumbers()}</ul>
        {page && (
          <div className="pages_found__title">
            {pages} {t("pages__title")}
          </div>
        )}
      </div>
    </div>
  );
};

export const ZoozaTableActions: React.FC<ZoozaTableProps> = ({ children }) => {
  return (
    <div className="results_table_footer">
      <div className="pager">
        <ul className="pages">{children}</ul>
      </div>
    </div>
  );
};
