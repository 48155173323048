import React from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { Registration } from "../../types/registration-types";
import { useTranslation } from "react-i18next";

export const ClientComponent = ({
  registration,
}: {
  registration: Registration | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <ZoozaCard>
      <ZoozaCardHeader icon="icon-user-female" title={t("title_client")} />
      <ZoozaCardRow>
        <ZoozaCardCell>
          <span aria-label={t("client_name__title")}>
            {t("client_name__title")}
          </span>
          <p>{registration?.__users__full_name}</p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("client_email__title")}>
            {t("client_email__title")}
          </span>
          <p>{registration?.__users__email}</p>
        </ZoozaCardCell>
        <ZoozaCardCell>
          <span aria-label={t("client_phone__title")}>
            {t("client_phone__title")}
          </span>
          <p>{registration?.__users__phone}</p>
        </ZoozaCardCell>
      </ZoozaCardRow>
      <ZoozaCardActions>
        {" "}
        {/* <button className="z2">{t("open_in_app")}</button> */}
      </ZoozaCardActions>
    </ZoozaCard>
  );
};
