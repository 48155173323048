import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ZoozaApi } from "../../services/Axios";
import { useTranslation } from "react-i18next";
import { Event, Registration, Schedule } from "../../types/registration-types";

import "moment/min/locales";

import { RegistrationComponent } from "./registartion-component";
import { ExtraFieldComponent } from "./extra-field-component";
import { ClientComponent } from "./client-component";
import { GroupComponent } from "./group-component";
import { PaymentCompoment } from "./payment-component";
import { EventsCard } from "../../components/event-component/events-card";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import moment from "moment";
import { PaymentModalCreation } from "../create-registration/payment-modal-creation";
import { AddPaymentModal } from "../../components/add-payment-modal";
export const RegistrationPage = () => {
  const [registration, setRegistration] = useState<Registration>();
  const [events, setEvents] = useState<Event[]>([]);
  const [schedule, setSchedule] = useState<Schedule>();
  const [open, setOpen] = React.useState(false);
  const { id } = useParams();
  const { courses } = useContext<ZoozaContextTypes>(ZoozaContext);

  const fetch = async () => {
    const { data: registration } = await ZoozaApi.get(`/registrations/${id}`);
    const { data: events } = await ZoozaApi.get(
      `events?schedule_id=${registration?.schedule_id}`
    );
    const { data: schedule } = await ZoozaApi.get(
      `/schedules/${registration?.schedule_id}`
    );
    setRegistration(registration);
    setEvents(events);
    setSchedule(schedule);
  };
  useEffect(() => {
    fetch();
  }, []);

  const { t } = useTranslation();
  const course = courses?.find(
    (course) => course.id === registration?.course_id
  );
  const upComingEvent = events?.find((event) =>
    moment(event.date).isAfter(moment())
  );

  const pastEvent = events?.find((event) =>
    moment(event.date).isBefore(moment())
  );

  const event_id =
    upComingEvent !== undefined ? upComingEvent?.id : pastEvent?.id;

  return (
    <div className="z2 app_page_layout">
      <div className="z2 app_page_toolbar"></div>
      <nav className="z2 quick_links">
        <p>{t("global__quick_links")}</p>

        <Link to="payments">{t("payments__title")}</Link>
        <Link
          className={event_id === undefined ? "disabled" : ""}
          to={event_id !== undefined ? `/calendar/${event_id}` : "#"}
        >
          {t("attendance__title")}
        </Link>
      </nav>
      <div className="app_page_body">
        <div className="app_page_column span_2">
          <RegistrationComponent registration={registration} />
          {course?.use_extra_fields && (
            <ExtraFieldComponent
              extra_fields={registration?.extra_fields}
              course={course}
              registration={registration}
            />
          )}
        </div>
        <div className="app_page_column span_2">
          <ClientComponent registration={registration} />
          <GroupComponent registration={registration} schedule={schedule} />
          <PaymentCompoment registration={registration} setOpen={setOpen} />
        </div>
        <div className="app_page_column span_4">
          <EventsCard
            setEvents={setEvents}
            events={events}
            limit={course?.registration_type === "single" ? 1 : undefined}
            schedule_id={registration?.schedule_id}
            course_id={registration?.course_id}
            paied={registration?.__calc__paid}
            toPay={registration?.__calc__balance}
            registration_id={registration?.id}
            getEvents={fetch}
          />
        </div>
      </div>

      <AddPaymentModal
        open={open}
        setOpen={setOpen}
        order_id={registration?.id}
        type="payment"
      />
    </div>
  );
};
