import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ZoozaContextTypes } from "../types/types";
import { ZoozaContext } from "../services/zooza.context";
import { bake_cookie, delete_cookie } from "../services/Axios";
import { useTranslation } from "react-i18next";

export const NavBar = () => {
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);
  const [panelOpened, setPanelOpened] = useState<any>(null);
  const navigate = useNavigate();
  const companies = userData?.user.companies;
  const user = userData?.user;

  const togglePanel = (panelName: string | any) => {
    setPanelOpened(panelName); // Open the selected panel
  };
  const { t } = useTranslation();

  const LogOut = () => {
    delete_cookie("token");
    delete_cookie("token-legacy");
    delete_cookie("active_company");
    delete_cookie("active_company-legacy");
    navigate("/login");
    window.location.reload();
  };

  const menuItems = [
    {
      name: t("calendar__title"),
      link: "/calendar",
    },

    {
      name: t("registrations__title"),
      link: "/registrations",
    },
  ];
  useEffect(() => {
    if (window.screen.width > 699) {
      setPanelOpened("mobile_menu");
    }
  }, []);
  const handleRedirect = () => {
    if (window.screen.width < 699) {
      togglePanel(null);
    }
  };

  return (
    <nav
      style={
        window.screen.width > 699
          ? {
              position: "fixed",
              minHeight: window.screen.width > 699 ? "100vh" : "",
              width: window.screen.width > 699 ? "200px" : "",
            }
          : {}
      }
      className="z2 app_menu"
    >
      <div className="z2 app_header">
        <Link onClick={handleRedirect} to="/" className="logo_zooza">
          <img
            width="67"
            height="32"
            src="https://app.zooza.sk/assets/logo_zooza.svg"
            alt="Zooza Logo"
          />
        </Link>
        {
          <>
            <a
              className="app_header_item"
              onClick={() => togglePanel("mobile_menu")}
            >
              <em className="icon-menu"></em> <span>Menu</span>
            </a>
            <a
              className="app_header_item"
              onClick={() => togglePanel("company_menu")}
            >
              <em className="icon-user"></em>{" "}
              <span>
                {user?.first_name?.[0]}
                {user?.last_name?.[0]}
              </span>
            </a>
          </>
        }
      </div>
      <div
        className={
          panelOpened === "mobile_menu"
            ? "nav_items opened"
            : "nav_items hidden"
        }
      >
        <ul>
          {menuItems.map((item, index) => (
            <li key={index}>
              <NavLink className="z2" onClick={handleRedirect} to={item.link}>
                {/* <em className={`icon-${item.icon}`}></em> */}
                <span>{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      <div
        className={
          panelOpened === "company_menu"
            ? "nav_items opened"
            : "nav_items hidden"
        }
      >
        <ul>
          <li>
            <a onClick={() => togglePanel("mobile_menu")}>
              <em className="icon-arrow-left"></em>
              <span>Späť</span>
            </a>
          </li>
          <li>
            <a onClick={LogOut}>
              <em className="icon-logout"></em>
              <span>Logout</span>
            </a>
          </li>
          {companies?.map((company: Company, index: number) => (
            <li key={index}>
              <a
                onClick={() => {
                  bake_cookie("active_company", company.id);
                  bake_cookie("active_company-legacy", company.id);
                  togglePanel("mobile_menu");
                  window.location.reload();
                }}
              >
                <em className="icon-company"></em>
                <span>{company.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>

      <a className="notifications"></a>
      <a className="user_settings" onClick={() => togglePanel("company_menu")}>
        <span>
          {user?.first_name} {user?.last_name}
        </span>
        <small className="text_muted">{userData?.company.name}</small>
      </a>
    </nav>
  );
};
