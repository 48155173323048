import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { LoginPage } from "./pages/login-page";
import {
  ZoozaApi,
  bake_cookie,
  delete_cookie,
  read_cookie,
} from "./services/Axios";
import "./App.css";
import { HomePage } from "./pages/home-page";
import { NavBar } from "./components/nav-bar";
import { ZoozaContext } from "./services/zooza.context";
import { ZoozaContextTypes } from "./types/types";
import { CalendarPage } from "./pages/calendar-page";
import { Loading } from "./components/loading";
import { BreadCrumbs } from "./components/breadcrumbs";
import { EventPage } from "./pages/event-page";
import { RegistrationsPage } from "./pages/registrations-page/registrations-page";

import { RegistrationPage } from "./pages/registartion-page/registration-page";
import { PaymentsPage } from "./pages/payment-page/payments-page";
import { CreateRegistrationPage } from "./pages/create-registration/create-registration-page";
import { CreateRegistrationProvider } from "./pages/create-registration/create-registration-context";

import { VerifyPage } from "./pages/verify-page";
import Auth from "./services/Auth";
import { UserData } from "./types/user-types";

const token = read_cookie("token") || read_cookie("token-legacy");

function App() {
  const [validUser, setValidUser] = useState<null | boolean>(null);
  const [verifyPage, setVerifyPage] = useState(false);
  const {
    setUserData,
    userData,
    setLoading,
    setCourses,
    setPlaces,
    setMembers,
    isAlowed,
  } = useContext<ZoozaContextTypes>(ZoozaContext);
  const heartbeat = window.location.hash === "#/heartbeat";
  console.log("heartbeat", heartbeat);

  const verifyMyCompany = () => {
    const logedCompanyID =
      read_cookie("active_company") || read_cookie("active_company-legacy");
    const myComapines = userData?.user.companies;
    const company = myComapines?.filter(
      (company) => Number(company.id) === Number(logedCompanyID)
    );

    if (
      (read_cookie("active_company") || read_cookie("active_company-legacy")) &&
      company?.length === 0 &&
      userData
    ) {
      delete_cookie("active_company");
      delete_cookie("active_company-legacy");
      bake_cookie("active_company", userData?.user.companies[0].id as number);
      bake_cookie(
        "active_company-legacy",
        userData?.user.companies[0].id as number
      );

      if (
        read_cookie("active_company") &&
        read_cookie("active_company-legacy")
      ) {
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    if (validUser) {
      verifyMyCompany();
    }
  }, [userData]);

  const verifyToken = () => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("/verify")) {
      setLoading?.(true);
      setVerifyPage(true);
    } else {
      setVerifyPage(false);
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);

  const navigate = useNavigate();

  const getCourses = async (data: UserData) => {
    if (
      !isAlowed?.(
        ["owner", "assistant", "receptionist", "member", "main_member"],
        data.user.role
      )
    ) {
      return;
    }
    try {
      const { data } = await ZoozaApi.get("/courses");
      setCourses?.(data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };
  const getPlaces = async (data: UserData) => {
    if (
      !isAlowed?.(
        ["owner", "assistant", "receptionist", "member", "main_member"],
        data.user.role
      )
    ) {
      return;
    }
    try {
      const { data } = await ZoozaApi.get(
        `/companies/${
          read_cookie("active_company") || read_cookie("active_company-legacy")
        }/places`
      );

      setPlaces?.(data.data);
    } catch (error) {
      console.error("Error fetching places:", error);
    }
  };
  const getMembers = async (data: UserData) => {
    if (
      !isAlowed?.(
        ["owner", "assistant", "receptionist", "main_member"],
        data.user.role
      )
    ) {
      return;
    }
    try {
      const { data } = await ZoozaApi.get(
        "/users?page_size=5000&roles=owner|member|main_member|external_member|assistant|main_member"
      );
      setMembers?.(data.data);
      return data;
    } catch (error) {
      console.error("Error fetching members:", error);
    }
  };

  useEffect(() => {
    const isUserLoggedIn = async () => {
      setLoading?.(true);
      if (!token) {
        setLoading?.(false);
        navigate("/login");
        return false;
      }
      try {
        const { data } = await ZoozaApi.get("/user");
        setUserData?.(data);
        setLoading?.(false);
        if (
          !(
            read_cookie("active_company") ||
            read_cookie("active_company-legacy")
          )
        ) {
          bake_cookie(
            "active_company",
            JSON.stringify(data.user.companies[0].id)
          );
        } else if (
          read_cookie("active_company") ||
          read_cookie("active_company-legacy")
        ) {
          verifyMyCompany();
        }
        if (!data.user_valid) {
          setValidUser(false);
          navigate("/login");
          return;
        } else if (data.user_valid === true) {
          setValidUser(true);
          fetch(data);
        } else {
          setValidUser(false);
          navigate("/login");
          return { data: data, valid: false };
        }
      } catch (err) {
        console.error("Fetch Error:", err);
        setLoading?.(false);
        return false;
      }
    };
    isUserLoggedIn();
  }, [read_cookie("active_company") || read_cookie("active_company-legacy")]);

  const fetch = async (data: UserData) => {
    setLoading?.(true);
    await Promise.all([getCourses(data), getPlaces(data), getMembers(data)]);
    setLoading?.(false);
  };

  return (
    <>
      <Routes>
        <Route path="/verify/:token" element={<VerifyPage />} />
        <Route path="/heartbeat" element={<div></div>} />
      </Routes>
      {validUser && !verifyPage ? (
        <div className="z2 app_body_layout default_sidebar">
          <NavBar />

          <div className="body">
            <div className="app_page_header">
              <BreadCrumbs />
            </div>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/calendar/:id" element={<EventPage />} />
              <Route
                element={
                  <Auth
                    allowedRoles={[
                      "owner",
                      "assistant",
                      "receptionist",
                      "main_member",
                      "receptionist",
                      "member",
                    ]}
                  />
                }
              >
                <Route path="/registrations" element={<RegistrationsPage />} />
                <Route
                  path="/registrations/:id"
                  element={<RegistrationPage />}
                />
                <Route
                  path="/registrations/:id/payments"
                  element={<PaymentsPage />}
                />
              </Route>

              <Route
                path="/registrations/create"
                element={
                  <CreateRegistrationProvider>
                    <CreateRegistrationPage />
                  </CreateRegistrationProvider>
                }
              />
            </Routes>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      )}

      <Loading />
    </>
  );
}

export default App;
