import React, { useContext, useEffect, useRef, useState } from "react";
import { DailyOverview } from "../components/calendar-viewer";
import {
  Member,
  Place,
  ZoozaContextTypes,
  CalendarEvent,
  AppRole,
} from "../types/types";
import "../styles/calendar.css";
import { ZoozaContext } from "../services/zooza.context";
import moment from "moment";
import { ZoozaApi } from "../services/Axios";

import { Link, useSearchParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import { DateTimePicker } from "../components/date-picker";
import { getTrainers } from "../services/common-functions";

import { ZoozaSelect } from "../components/inputs";
import { DailyAttendance } from "../types/schedue-type";
import { useTranslation } from "react-i18next";

export const CalendarPage = () => {
  const { t } = useTranslation();
  const { userData, setLoading, loading, places, roles } =
    useContext<ZoozaContextTypes>(ZoozaContext);
  const [members, setMembers] = useState<Member[]>([]);
  const [calendarData, setCalendarData] = useState<CalendarEvent[]>([]);
  const [date, setDate] = useState<any>(moment().format("YYYY-MM-DD"));

  const [selectedPlace, setSelectedPlace] = useState<Place>();
  const [searchParams, setSearchParams] = useSearchParams();

  const timeNow = moment().format("HH");
  const columnWidth = 150;

  function scrollToColumn() {
    const div = document.querySelector(".calendar");

    if (div) {
      const currentTime = parseInt(timeNow, 10);
      const targetScrollLeft = currentTime * columnWidth;

      div.scrollLeft = targetScrollLeft;
    }
  }

  useEffect(() => {
    scrollToColumn();
  }, [calendarData]);

  const getCalendarData = async () => {
    setLoading?.(true);
    try {
      const { data } = await ZoozaApi.get(
        `/calendar?start=${moment(date).format("YYYY-MM-DD")}&end=${moment(
          date
        ).format("YYYY-MM-DD")}`
      );

      setLoading?.(false);
      return data;
    } catch (err) {
      console.error("Fetch Error:", err);
      setLoading?.(false);
    }
  };

  type Response = {
    data: Place[] | Member[];
  };

  const fetchData = async () => {
    const responses: Response[] = await Promise.all([
      getCalendarData(),
      getTrainers(),
    ]);

    setCalendarData(responses[0] as unknown as CalendarEvent[]);
    setMembers(responses[1].data as Member[]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  useEffect(() => {
    getCalendarData().then((data) => setCalendarData(data));
  }, [date]);

  const handleDate = (navigate: number) => {
    const newDate = moment(date).add(navigate, "days").format("YYYY-MM-DD");
    setDate(newDate);
  };

  useEffect(() => {
    if (searchParams.get("place")) {
      handleFilterPlaces();
    }
  }, [searchParams.get("place")]);
  useEffect(() => {
    if (places && places.length > 0 && !searchParams.get("place")) {
      setSelectedPlace(places[0]);
    }
  }, [places]);
  useEffect(() => {
    if (places && places.length > 0 && searchParams.get("place")) {
      handleFilterPlaces();
    }
  }, [places]);

  const handleFilterPlaces = () => {
    const filteredPlaces = places?.filter(
      (place: Place) => String(place.id) === searchParams.get("place")
    );

    setSelectedPlace(filteredPlaces ? filteredPlaces[0] : undefined);
  };

  const role = userData?.user.role;
  const logedMember = members?.filter(
    (member) => member.id === userData?.user.id
  );
  const member_roles: AppRole[] = ["member", "external_member"];
  const isMember = member_roles.includes(
    logedMember?.[0]?.role.role as AppRole
  );

  const place_id = searchParams.get("place") || places?.[0].id;
  const place = places?.find((place) => place.id === Number(place_id));
  const lang = localStorage.getItem("i18nextLng") || "en";
  return (
    <div className="calendar-page">
      <div
        style={{
          display: "flex",
          maxHeight: 40,
          alignContent: "center",
        }}
      >
        <DateTimePicker
          required={false}
          date={date}
          help={""}
          setDate={setDate}
          format="iiii d.MM.yy"
        />

        <ZoozaSelect param={"place"}>
          {places?.map((place: Place) => (
            <option key={place.id} value={place.id}>
              {place.name}
            </option>
          ))}
        </ZoozaSelect>
      </div>
      <div className="calendar-buttons" style={{ display: "flex" }}>
        <a role="button" className="z2" onClick={() => handleDate(-1)}>
          <em className="icon-arrow-left"></em>
          {moment(date).subtract(1, "days").locale(lang).format("dddd")}
        </a>
        <div style={{ flex: 0.9 }}>
          <a
            role="button"
            className="z2"
            type="submit"
            onClick={() => handleDate(1)}
          >
            {moment(date).add(1, "days").locale(lang).format("dddd")}
            <em className="icon-arrow-right"></em>
          </a>
        </div>

        <Link to="/registrations/create/" role="button" className="z2 primary">
          {t("create_registration")}
        </Link>
      </div>

      {!loading && (
        <>
          <DailyOverview
            place={place}
            members={isMember ? logedMember : members}
            events={
              isMember
                ? calendarData.filter((e) => e.trainer_id === userData?.user.id)
                : calendarData
            }
            handleDate={handleDate}
            date={date}
          />
        </>
      )}
    </div>
  );
};
