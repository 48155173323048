import React from "react";
import { ReactComponent as Loader } from "../assets/img/loader.svg";
import { ReactComponent as PrimaryLoader } from "../assets/img/loader_primary.svg";

export const LoadingButton = (props: {
  loading: boolean;
  class?: string;
  style?: React.CSSProperties;
  name: string;
  primary?: boolean;
  onClick: (e?: any) => void | any;
  id?: string;
}) => {
  return (
    <button
      style={props.style}
      className={props.class}
      type="submit"
      onClick={props.onClick}
      id={props.id}
    >
      {!props.loading ? (
        props.name
      ) : props.primary ? (
        <PrimaryLoader clasName="spinner-button" />
      ) : (
        <Loader className="spinner-button" />
      )}
    </button>
  );
};
