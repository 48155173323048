import React, { useContext } from "react";
import {
  ZoozaCard,
  ZoozaCardHeader,
  ZoozaCardRow,
  ZoozaCardCell,
  ZoozaCardActions,
} from "../../components/zooza-card-components";
import { Registration } from "../../types/registration-types";
import { convertToCurrency } from "../../services/curency-service";
import { ZoozaContext } from "../../services/zooza.context";
import { ZoozaContextTypes } from "../../types/types";
import { Link } from "react-router-dom";

import { msg } from "../../services/common-functions";
import { useTranslation } from "react-i18next";

export const PaymentCompoment = ({
  registration,
  setOpen,
}: {
  registration: Registration | undefined;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { userData } = useContext<ZoozaContextTypes>(ZoozaContext);

  return (
    <>
      <ZoozaCard>
        <ZoozaCardHeader icon="icon-money-plant" title={t("payments__title")} />
        <ZoozaCardRow>
          <ZoozaCardCell>
            <span aria-label={t("payment_status")}>{t("payments_status")}</span>
            <p>{msg(registration?.payment_status)}</p>
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("debt__title")}>{t("debt__title")}</span>

            <p>
              {" "}
              {convertToCurrency(
                registration?.__calc__debt,
                userData?.company.region
              )}
            </p>
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("paid__title")}>{t("paid__title")}</span>
            <p>
              {" "}
              {convertToCurrency(
                registration?.__calc__paid,
                userData?.company.region
              )}
            </p>
          </ZoozaCardCell>
          <ZoozaCardCell>
            <span aria-label={t("total__title")}>{t("total__title")}</span>
            <p>
              {" "}
              {convertToCurrency(
                registration?.__calc__balance,
                userData?.company.region
              )}
            </p>
          </ZoozaCardCell>
        </ZoozaCardRow>
        <ZoozaCardActions>
          <button onClick={() => setOpen(true)} className="z2 primary">
            {t("add_payment_button")}
          </button>
          <button className="z2">
            <Link to="payments">{t("view_payments__link_label")}</Link>
          </button>
        </ZoozaCardActions>
      </ZoozaCard>
    </>
  );
};
