import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import axios from "axios";
import en from "./locales/en.json";
import sk from "./locales/sk.json";
import cs from "./locales/cs.json";
import de from "./locales/de.json";
import ru from "./locales/ru.json";
import pl from "./locales/pl.json";
const initI18n = async () => {
  // if (window.location.origin.includes("frontdesk-test.zooza.app")) {
  //   const url = window.location.origin + window.location.pathname + "locale";
  //   const selectedLanguage = localStorage.getItem("i18nextLng") || "en";
  //   try {
  //     const langData = (await axios.get(`${url}/${selectedLanguage}.json`))
  //       .data;

  //     i18n.use(initReactI18next).init({
  //       debug: true,
  //       supportedLngs: ["en", "sk", "cs", "de", "ru", "pl"],
  //       returnEmptyString: false,
  //       fallbackLng: "en",
  //       lng: selectedLanguage,
  //       interpolation: {
  //         escapeValue: false,
  //       },
  //       resources: {
  //         [selectedLanguage]: {
  //           translation: langData,
  //         },
  //       },
  //     });
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error loading translations:", error);
  //   }
  // } else {
  try {
    i18n.use(initReactI18next).init({
      debug: true,
      fallbackLng: "en",
      supportedLngs: ["en", "sk", "cs", "de", "ru", "pl"],
      returnEmptyString: false,
      lng: localStorage.getItem("i18nextLng") || "en",
      interpolation: {
        escapeValue: false,
      },
      resources: {
        sk: {
          translation: sk,
        },
        en: {
          translation: en,
        },
        cs: {
          translation: cs,
        },
        de: {
          translation: de,
        },
        ru: {
          translation: ru,
        },
        pl: {
          translation: pl,
        },
      },
    });
  } catch (e) {}
};
// };

initI18n();

export default i18n;
