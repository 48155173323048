import { t, use } from "i18next";
import React, { useEffect, useState } from "react";
import {
  ZoozaCard,
  ZoozaCardActions,
  ZoozaCardCell,
  ZoozaCardHeader,
  ZoozaCardRow,
} from "../../components/zooza-card-components";
import {
  InvoiceAPIResponse,
  InvoiceProfiles,
} from "../../types/payments-types";
import { ZoozaApi } from "../../services/Axios";
import { Registration } from "../../types/registration-types";
import { Modal } from "../../components/modal";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ZoozaSelect } from "../../components/inputs";
import { Course } from "../../types/courses-types";
import { useParams } from "react-router-dom";
import { LoadingButton } from "../../components/loading-button";
import { msg } from "../../services/common-functions";
import { ErrorType } from "../../types/types";
type Options = {
  date: string;
  paymentType: string;
  note: string;
  paymentProfile?: number;
};
const lang = localStorage.getItem("i18nextLng") || "en";
export const InvoiceComponent = ({
  invoiceProfiles,
  registration,
  course,
}: {
  invoiceProfiles: InvoiceProfiles[] | undefined;
  registration: Registration | undefined;
  course: Course | undefined;
}) => {
  const { id } = useParams();
  const [selectedProfile, setSelectedProfile] = useState<number | undefined>();
  const [open, setOpen] = useState(false);
  const [aftergenerating, setAftergenerating] = useState(false);
  const [invoices, setInvoices] = useState<InvoiceAPIResponse>();
  const [invoiceLink, setInvoiceLink] = useState<
    { id: number; link: string | null } | undefined
  >();
  const [invoiceLinkGenerating, setInvoiceLinkGenerating] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [options, setOptions] = useState<Options>({
    date: moment().format("YYYY-MM-DD"),
    paymentType: "ina",
    note: "",
  });
  const [error, setError] = useState<ErrorType>();
  useEffect(() => {
    setOptions({ ...options, note: course?.name || "" });
  }, [course]);
  const onProfileChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOptions({ ...options, paymentProfile: Number(e.target.value) });
  };
  const getInvoices = async () => {
    const { data: invoice } = await ZoozaApi.get(
      `/customer_invoices/?order_id=${id}&order_type=course`
    );
    setInvoices(invoice);
  };
  useEffect(() => {
    getInvoices();
  }, []);
  const onGenerateInvoice = async () => {
    setInvoiceLoading(true);
    try {
      const { data } = await ZoozaApi.post("/customer_invoices", {
        order_id: registration?.id,
        order_type: "course",
        skip_queue: true,
        options: {
          date: options.date,
          text: options.note,
          payment_method: options.paymentType,
        },
      });
      if (data === true) {
        setOpen(false);
        setAftergenerating(true);
      } else if (typeof data === "number") {
        try {
          const { data: invoice } = await ZoozaApi.get(
            `/customer_invoices/${data}`
          );
        } catch (e) {
          console.log(e);
        }
      }
      setInvoiceLoading(false);
      getInvoices();
      setOpen(false);
    } catch (e) {
      console.log(e);
      setError(e as ErrorType);
    }
  };
  const onGenerateInvocieLink = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any
  ) => {
    setInvoiceLink({ id: e.target.id, link: null });
    setInvoiceLinkGenerating(true);
    const id = e.target.id;

    try {
      const { data } = await ZoozaApi.get(`/customer_invoices/${id}/download`);
      setInvoiceLink({ id: id, link: data.url });
    } catch (e) {
      console.error(e);
      setError(e as ErrorType);
    }
    setInvoiceLinkGenerating(false);
  };

  return (
    <>
      <ZoozaCard span={2}>
        <ZoozaCardHeader title={t("invoices__title")} icon="icon-bill" />
        <div className="card_row_list_item mobile_single_column span_1">
          <div className="list_item_label">
            <p>{t("course_settings__price__invoice_profile")}</p>
          </div>

          <div className="list_item_actions span_2">
            <select
              onChange={onProfileChange}
              className="z2"
              name="biling_profiles"
              id="biling_profiles"
            >
              <option value="">{t("initial_payment_profile")}</option>
              {invoiceProfiles?.map((profile) => (
                <option key={profile.id} value={profile.id}>
                  {profile.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {aftergenerating ? (
          <div className="card_row_list_item notice success">
            <div className="list_item_label span_3">
              <p> {t("registration_payments__invoice_created")}</p>
            </div>
          </div>
        ) : (
          <div className="list_item_label span_3">
            <p>
              <small className="text_info">
                {t("registration_payments__create_invoice__help")}
              </small>
            </p>
          </div>
        )}
        {invoices?.data.map((invoice) => (
          <ZoozaCardRow key={invoice.id}>
            <ZoozaCardCell>
              <div className="list_item_label">
                <p>
                  {t("registration_payments__invoice__number")}:{" "}
                  {invoice.invoice_no}
                </p>
                <p>
                  {t("registration_payments__invoice__date")}: {invoice.created}
                </p>
                <p>
                  {t("registration_payments__invoice__payment_method")}:{" "}
                  {invoice.invoice_data?.f.f_payment}
                </p>
              </div>
              <div className="list_item_actions">
                <LoadingButton
                  name={t("view_invoice_link")}
                  loading={
                    invoiceLinkGenerating &&
                    Number(invoiceLink?.id) === invoice.id
                  }
                  class="z2"
                  id={String(invoice.id)}
                  onClick={onGenerateInvocieLink}
                />

                {Number(invoiceLink?.id) === invoice.id &&
                  invoiceLink?.link && (
                    <a href={invoiceLink?.link} target="_blank">
                      {t("download_invoice")}
                    </a>
                  )}
                {Number(invoiceLink?.id) === invoice.id &&
                  invoiceLink?.link === null && <p>{t("invoice_not_ready")}</p>}
              </div>
            </ZoozaCardCell>
          </ZoozaCardRow>
        ))}

        <ZoozaCardActions>
          <button onClick={() => setOpen(true)} className="z2 primary">
            {t("generate_invoice__button")}
          </button>
        </ZoozaCardActions>
      </ZoozaCard>
      <InvoiceOptionsModal
        open={open}
        setOpen={setOpen}
        onSubmit={onGenerateInvoice}
        setOptions={setOptions}
        options={options}
        error={error}
        loading={invoiceLoading}
      />
    </>
  );
};

export const InvoiceOptionsModal = ({
  open,
  setOpen,
  onSubmit,
  setOptions,
  options,
  error,
  loading,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
  setOptions: (options: any) => void;
  options: Options;
  error?: ErrorType;
  loading?: boolean;
}) => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement> | any) => {
    setOptions({ ...options, paymentType: e.target.value });
  };
  const paymentTypes = [
    { value: "ina", label: t("payment_type_other") },
    { value: "hotovost", label: t("payment_type_cash") },
    { value: "prevod", label: t("payment_type_bank") },
    { value: "eprovider", label: t("payment_type_online") },
    { value: "karta", label: t("payment_type_credit") },
  ];

  return (
    <Modal
      submitButton={t("save__button")}
      cancelButton={t("cancel_button")}
      buttonDisabled={false}
      title={t("invoice_options__title")}
      isOpen={open}
      onClose={() => setOpen(false)}
      onSubmit={onSubmit}
      loading={loading}
      errors={error}
    >
      {" "}
      <div style={{ padding: 10 }}>
        <fieldset>
          <legend>{t("invoice_date")}</legend>
          <DatePicker
            onChange={(date) => setOptions({ ...options, date: String(date) })}
            selected={moment(options.date).toDate()}
            dateFormat="yyyy-MM-dd"
            locale={lang}
          />
        </fieldset>
        <fieldset>
          <legend>{t("payment_type")}</legend>
          <ZoozaSelect param="" onChange={onChange} value={options.paymentType}>
            {paymentTypes.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </ZoozaSelect>
        </fieldset>
        <fieldset>
          <legend>{t("note__title")}</legend>
          <textarea
            onChange={(e) => setOptions({ ...options, note: e.target.value })}
            name="note"
            id="note"
            value={options.note}
          ></textarea>
        </fieldset>
      </div>
    </Modal>
  );
};
