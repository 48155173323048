import React from "react";
import i18n from "../locale";

interface Languages {
  name: string;
  code: string;
}

export const LanguageSelector = () => {
  const languages: Languages[] = [
    { name: "English", code: "en" },
    { name: "Slovenský", code: "sk" },
    { name: "Deutsch", code: "de" },
    { name: "Česky", code: "cs" },
    { name: "Polski", code: "pl" },
    { name: "Русский", code: "ru" },
  ];
  const handleChangeLanguage = (code: string) => {
    localStorage.setItem("i18nextLng", code);
    i18n.changeLanguage(code);
  };
  return (
    <ul className="language_selector">
      {languages.map((language: Languages, i: number) => {
        return (
          <li key={i}>
            <a
              href="#"
              id={language.code}
              onClick={() => handleChangeLanguage(language.code)}
            >
              {language.name}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
export const lang = localStorage.getItem("i18nextLng") || "en";
