import React from "react";
import { useTranslation } from "react-i18next";

export const ProgressButtons = ({
  setProgress,
  next,
  back,
  disabled,
}: {
  setProgress?: (value: number) => void;
  next: number;
  back: number;
  disabled?: boolean;
}) => {
  const progressing = (e: React.MouseEvent) => {
    const { id, value } = e.target as HTMLInputElement;
    if (id === "progress") {
      setProgress?.(Number(value));
    }
  };
  const { t } = useTranslation();
  return (
    <>
      <button
        onClick={progressing}
        value={next}
        id="progress"
        className="z2 primary"
        disabled={disabled}
      >
        {t("continue__button")}
      </button>
      <button className="z2" id="progress" value={back} onClick={progressing}>
        {t("back__button")}
      </button>
    </>
  );
};
